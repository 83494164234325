import { GiftOutlined } from "@ant-design/icons";
import { Layout, Menu, Badge } from "antd";
import { ArrowLeftIcon } from "components/svgs/ArrowLeftIcon";
import { ArrowRightIcon } from "components/svgs/ArrowRightIcon";
import { UserGroup } from "configs/constants";
import { selectGroup } from "modules/auth/store/authSlice";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  ClientIcon,
  DashboardIcon,
  GroupIcon,
  OrderIcon,
  SettingIcon,
  HistoryIcon,
  InvoicingIcon,
  BroadcastIcon
} from "../../../components/svgs";
import { useWindowSize } from "../../../helper/hooks";
import "./AdminSiderContent.scss";
import { UserRole } from "configs/constants";
import { selectRole } from "modules/auth/store/authSlice";

const { Sider } = Layout;
const { SubMenu } = Menu;
const getPatchName = (str) => {
  let rs = "/admin";
  const path = str && str[str.length - 1] == "/" ? str.substring(0, str.length - 1) : str;
  switch (true) {
    case path === "/admin":
      rs = "/admin/dashboard";
      break;
    case path.includes("/admin") && path.includes("/dashboard/map"):
      rs = "/admin/dashboard/map";
      break;
    case path.includes("/admin") && path.includes("/dashboard/finance"):
      rs = "/admin/dashboard/finance";
      break;
    case path.includes("/admin") && path.includes("/dashboard"):
      rs = "/admin/dashboard";
      break;
    case path.includes("/admin") && path.includes("/users/clients"):
      rs = "/admin/users/clients";
      break;
    case path.includes("/admin") && path.includes("/users/territories"):
      rs = "/admin/users/territories";
      break;
    case path.includes("/admin") && path.includes("/users/admins"):
      rs = "/admin/users/admins";
      break;
    case path.includes("/admin") && path.includes("/users/drivers"):
      rs = "/admin/users/drivers";
      break;
    case path.includes("/admin") && path.includes("/clients"):
      rs = "/admin/clients";
      break;
    case path.includes("/admin") && path.includes("/settings/package-types"):
      rs = "/admin/settings/package-types";
      break;
    case path.includes("/admin") && path.includes("/settings/vehicle-categories"):
      rs = "/admin/settings/vehicle-categories";
      break;
    case path.includes("/admin") && path.includes("/settings/services"):
      rs = "/admin/settings/services";
      break;
    case path.includes("/admin") && path.includes("/settings/territories"):
      rs = "/admin/settings/territories";
      break;
    case path.includes("/admin") && path.includes("/routes"):
      rs = "/admin/routes";
      break;
    case path.includes("/admin") && path.includes("/history"):
      rs = "/admin/history";
      break;
    case path.includes("/admin") && path.includes("/invoicing"):
      rs = "/admin/invoicing";
      break;
    case path.includes("/admin") && path.includes("/settings/version"):
      rs = "/admin/settings/version";
      break;
    case path.includes("/admin") && path.includes("/pool/packages"):
      rs = "/admin/pool/packages";
      break;
    case path.includes("/admin") && path.includes("/pool/orders"):
      rs = "/admin/pool/orders";
      break;
  }
  return rs;
};

const AdminSiderContent = () => {
  const location = useLocation();
  const pathname = getPatchName(getPatchName(location.pathname));
  const [siderKey, setSiderKey] = useState(pathname);
  const [openKey, setOpenKey] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const handleClickCollapse = () => {
    setCollapsed(!collapsed);
  };

  const role = useSelector(selectRole);

  const history = useHistory();

  const size = useWindowSize();
  const height = size.height - 64;

  const isFlexioAdmin = useSelector(selectGroup) === UserGroup.FLEXIO;

  useEffect(() => {
    if (pathname.includes("/users/")) {
      setOpenKey(["users"]);
    } else if (pathname.includes("/settings/")) {
      setOpenKey(["settings"]);
    } else if (pathname.includes("/pool/")) {
      setOpenKey(["pool"]);
    } else if (pathname.includes("/dashboard") || pathname === "/admin") {
      setOpenKey(["dashboard"]);
    }
  }, [pathname]);

  const handleClickMenuItem = (value) => {
    const key = value?.key;
    setSiderKey(getPatchName(key));
    setOpenKey([]);
    key && history.push(`${key}`);
  };

  const handleClickMenuSub = (value) => {
    const newOpenKeys = [...openKey];
    if (newOpenKeys.includes(value.key)) {
      const index = newOpenKeys.findIndex((key) => key === value.key);
      delete newOpenKeys[index];
      setOpenKey(newOpenKeys);
    } else {
      newOpenKeys.push(value.key);
      setOpenKey(newOpenKeys);
    }
  };
  return (
    <Sider
      className="admin-sider-content-component"
      trigger={null}
      collapsible
      width={228}
      collapsed={collapsed}
    >
      <Menu
        mode="inline"
        openKeys={openKey}
        selectedKeys={[siderKey]}
        onClick={handleClickMenuItem}
      >
        <SubMenu
          key="dashboard"
          icon={
            <span className="anticon">
              <DashboardIcon />
            </span>
          }
          title="Dashboard"
          onTitleClick={handleClickMenuSub}
        >
          <Menu.Item
            key="/admin/dashboard"
            icon={<span className="iconClass operation-icon"></span>}
          >
            Operations
          </Menu.Item>
          {/*<Menu.Item key="/admin/dashboard/finance">Finance</Menu.Item>*/}
          <Menu.Item
            key="/admin/dashboard/map"
            icon={<span className="iconClass mapIcon"></span>}
          >
            Map
          </Menu.Item>
          {window.ROUTES_DELAYED && (
            <Menu.Item
              key="/admin/delayed-routes"
              icon={<span className="iconClass clock-icon"></span>}
            >
              Dispatch Center
            </Menu.Item>
          )}
        </SubMenu>
        <Menu.Item
          key="/admin/routes"
          icon={
            <span className="anticon">
              <OrderIcon />
            </span>
          }
        >
          Active Routes
        </Menu.Item>
        {/* pool */}
        <SubMenu
          key="pool"
          icon={
            <span className="anticon">
              <GiftOutlined />
            </span>
          }
          title="Flexio Pool"
          onTitleClick={handleClickMenuSub}
        >
          <Menu.Item
            key="/admin/pool/packages"
            icon={<span className="iconClass packagesIcon"></span>}
          >
            Packages
          </Menu.Item>
          {window.POOL_ORDER_LIST && (
            <Menu.Item
              key="/admin/pool/orders"
              icon={<span className="iconClass mapIcon"></span>}
            >
              Orders
            </Menu.Item>
          )}
          <Menu.Item
            key="/admin/pool/promo-codes"
            icon={<span className="iconClass couponIcon"></span>}
          >
            Promo Codes
          </Menu.Item>
        </SubMenu>
        <Menu.Item
          key="/admin/invoicing"
          icon={
            <span className="anticon">
              <InvoicingIcon />
            </span>
          }
        >
          Invoicing
        </Menu.Item>
        <Menu.Item
          key="/admin/history"
          icon={
            <span className="anticon">
              <HistoryIcon />
            </span>
          }
        >
          History
        </Menu.Item>
        <Menu.Item
          key="/admin/clients"
          icon={
            <span className="anticon">
              <ClientIcon />
            </span>
          }
        >
          Companies
        </Menu.Item>
        {role === UserRole.ADMIN && (
          <SubMenu
            key="users"
            icon={
              <span className="anticon">
                <GroupIcon />
              </span>
            }
            title="Users"
            onTitleClick={handleClickMenuSub}
          >
            <Menu.Item
              key="/admin/users/clients"
              icon={<span className="iconClass clientIcon"></span>}
            >
              Client
            </Menu.Item>
            {isFlexioAdmin && (
              <Menu.Item
                key="/admin/users/territories"
                icon={<span className="iconClass territoryIcon"></span>}
              >
                Territory
              </Menu.Item>
            )}
            <Menu.Item
              key="/admin/users/drivers"
              icon={<span className="iconClass driverClass"></span>}
            >
              Driver
            </Menu.Item>
            {isFlexioAdmin && (
              <Menu.Item
                key="/admin/users/admins"
                icon={<span className="iconClass adminClass"></span>}
              >
                Admin
              </Menu.Item>
            )}
          </SubMenu>
        )}
        {isFlexioAdmin && role !== UserRole.VIEWER && (
          <>
            <SubMenu
              key="settings"
              icon={
                <span className="anticon">
                  <SettingIcon />
                </span>
              }
              title="Settings"
              onTitleClick={handleClickMenuSub}
            >
              <Menu.Item
                key="/admin/settings/saas-countries"
                icon={<span className="iconClass countriesIcon"></span>}
              >
                SAAS Countries
              </Menu.Item>
              <Menu.Item
                key="/admin/settings/territories"
                icon={<span className="iconClass territoryIcon"></span>}
              >
                Territories
              </Menu.Item>
              <Menu.Item
                key="/admin/settings/services"
                icon={<span className="iconClass servicesIcon"></span>}
              >
                Services
              </Menu.Item>
              <Menu.Item
                key="/admin/settings/vehicle-categories"
                icon={<span className="iconClass servicesIcon"></span>}
              >
                Vehicle Categories
              </Menu.Item>
              <Menu.Item
                key="/admin/settings/package-types"
                icon={<span className="iconClass packagesIcon"></span>}
              >
                Package Types
              </Menu.Item>
              <Menu.Item
                key="/admin/settings/failed-registration"
                icon={<span className="iconClass failedIcon"></span>}
              >
                Failed Registration
              </Menu.Item>
              <Menu.Item
                key="/admin/settings/version"
                icon={<span className="iconClass territoryIcon"></span>}
              >
                Version
              </Menu.Item>
            </SubMenu>
          </>
        )}
        <Menu.Item
          key="/admin/driver-broadcasts"
          icon={
            <span className="anticon">
              <BroadcastIcon />
            </span>
          }
        >
          Driver Broadcast
        </Menu.Item>
      </Menu>
      <div className="button-collapse" onClick={handleClickCollapse}>
        <span>{collapsed ? <ArrowRightIcon /> : <ArrowLeftIcon />}</span>
      </div>
    </Sider>
  );
};
export default AdminSiderContent;
