import React from "react";

export const DriversIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.125 4.5C6.13477 4.5 5.25293 5.14453 4.96875 6.09375L3.9375 9.53906L2.48438 9.04688L2.01562 10.4531L3.49219 10.9453L3.02344 12.5391C3.00586 12.6064 2.99707 12.6797 3 12.75V18C3 18.0234 3 18.0469 3 18.0703V18.75C3 19.1631 3.33691 19.5 3.75 19.5H6L6.25781 18.75H17.7422L18 19.5H20.25C20.6631 19.5 21 19.1631 21 18.75V18.1172C21.0029 18.0791 21.0029 18.0381 21 18V12.75C21.0029 12.6797 20.9941 12.6064 20.9766 12.5391L20.5078 10.9453L21.9844 10.4531L21.5156 9.04688L20.0625 9.53906L19.0312 6.09375C18.7471 5.14453 17.8652 4.5 16.875 4.5H7.125ZM7.125 6H16.875C17.209 6 17.5049 6.21973 17.6016 6.53906L18.5625 9.75H5.4375L6.39844 6.53906C6.49512 6.2168 6.79102 6 7.125 6ZM4.99219 11.25H19.0078L19.5 12.8906V17.25H4.5V12.8906L4.99219 11.25ZM6.375 12C5.75391 12 5.25 12.5039 5.25 13.125C5.25 13.7461 5.75391 14.25 6.375 14.25C6.99609 14.25 7.5 13.7461 7.5 13.125C7.5 12.5039 6.99609 12 6.375 12ZM17.625 12C17.0039 12 16.5 12.5039 16.5 13.125C16.5 13.7461 17.0039 14.25 17.625 14.25C18.2461 14.25 18.75 13.7461 18.75 13.125C18.75 12.5039 18.2461 12 17.625 12ZM9 14.25L8.0625 16.5H9.67969L10.0078 15.75H13.9922L14.3203 16.5H15.9375L15 14.25H9Z"
        fill="#E4E7F6"
      />
    </svg>
  );
};
