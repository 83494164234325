import React from "react";
import { Popconfirm, Table, Tag } from "antd";
import { formatToUSD } from "helper/util";
import moment from "moment";
import { TrashIcon } from "components/svgs";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const PromoCodeList = ({ data, getAllCodes, loading, setLoading, deletePromoCode }) => {
  const colums = [
    {
      title: "Promo Code",
      dataIndex: "discountName"
    },
    {
      title: "Discount Amount",
      dataIndex: "amountOff",
      render: (value) => <div>{value > 0 ? formatToUSD(value) : "N/A"}</div>
    },
    {
      title: "Discount Percent",
      dataIndex: "percentOff",
      render: (value) => <>{value > 0 ? <div>{value}%</div> : "N/A"}</>
    },
    {
      title: "Status",
      dataIndex: "active",
      render: (value) => {
        return (
          <Tag color={value ? "#00C853" : "#888888"}>{value ? "Active" : "Inactive"}</Tag>
        );
      }
    },
    {
      title: "Expires",
      dataIndex: "expiryDate",
      render: (value) => moment(value).format("LLL")
    },
    {
      title: "Action",
      render: (record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center"
          }}
        >
          <Popconfirm
            title="Are you sure you want to delete this promo code?"
            onConfirm={async () => {
              setLoading(true);
              await deletePromoCode(record?.id);
              await getAllCodes();
              setLoading(false);
            }}
          >
            <TrashIcon style={{ cursor: "pointer" }} />
          </Popconfirm>
          <Link to={`/admin/pool/promo-codes/edit/${record?.id}`}>
            <EditOutlined />
          </Link>
        </div>
      )
    }
  ];
  return (
    <>
      <Table dataSource={data} columns={colums} loading={loading} pagination={false} />
    </>
  );
};

export default PromoCodeList;
