import React, { useEffect, useState, useRef } from "react";
import ReactMapboxGl, { Marker, ZoomControl } from "react-mapbox-gl";
import axios from "axios";
import "mapbox-gl/dist/mapbox-gl.css";
import "./DefaultMapbox.scss";
import mapboxgl from "mapbox-gl";
import { setMapboxRTLTextPlugin } from "helper/util";
import { CCP_DEFAULT_CENTER } from "configs/constants";
import { DEFAULT_CENTER } from "configs/constants";

mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
setMapboxRTLTextPlugin(mapboxgl);
const Map = ReactMapboxGl({
  accessToken: window.REACT_APP_MAPBOX_KEY
});

const defaultCenter =
  window.REACT_APP_DD_ENV === "ccpexpress" ? CCP_DEFAULT_CENTER : DEFAULT_CENTER;

const DefaultMapbox = ({ address, setAddress, key, defaultZoom = [15] }) => {
  const [markerTest, setMarkerTest] = useState(address);
  const [center, setCenter] = useState(
    window.REACT_APP_DD_ENV === "ccpexpress"
      ? {
          lat: CCP_DEFAULT_CENTER[1],
          lng: CCP_DEFAULT_CENTER[0]
        }
      : {
          lat: DEFAULT_CENTER[1],
          lng: DEFAULT_CENTER[0]
        }
  );

  useEffect(() => {
    if (!address) return;
    setMarkerTest(address);
    setCenter({
      lat:
        address?.lngLat && address?.lngLat.length > 1
          ? address.lngLat[1]
          : defaultCenter[1],
      lng:
        address?.lngLat && address?.lngLat.length > 1
          ? address.lngLat[0]
          : defaultCenter[0]
    });
  }, [address]);

  const _onClickmap = (map, e) => {
    const lngLatClick = e.lngLat;
    axios
      .get(
        `${window.REACT_APP_API_ENDPOINT}/google/googlegeo?lat=${lngLatClick.lat}&lng=${lngLatClick.lng}`
      )
      .then((res) => {
        setAddress({
          lngLat: [lngLatClick.lng, lngLatClick.lat],
          address: res.data.results[0].formatted_address
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onMapload = (map) => {
    map.addControl(new mapboxgl.FullscreenControl(), "top-right");
  };

  const handleClickRef = useRef(_onClickmap);
  handleClickRef.current = _onClickmap; // update reference with every render

  const showMarker = React.useMemo(
    () =>
      markerTest?.lngLat &&
      markerTest.lngLat?.length && (
        <Marker coordinates={markerTest.lngLat || []}>
          <img style={{ width: "30px" }} src="/icons/location.png" />
        </Marker>
      ),
    [markerTest]
  );

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <Map
        key={key}
        container="map"
        onStyleLoad={onMapload}
        zoom={defaultZoom}
        // use the reference (which is never changing, only the .current value changes)
        onClick={(map, event) => handleClickRef.current(map, event)}
        containerStyle={{
          height: "100%",
          width: "100%"
        }}
        movingMethod="easeTo"
        style="mapbox://styles/theprof/cks08jqsk3k9o18p67chbbsst"
        // defaultCenter={defaultCenter}
        center={center}
      >
        <ZoomControl position="bottom-right" />
        {showMarker}
      </Map>
    </div>
  );
};

export default DefaultMapbox;
