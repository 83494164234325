import {
  GiftOutlined,
  FlagFilled,
  LineChartOutlined,
  QuestionCircleFilled,
  HomeOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Image, Layout, Menu } from "antd";
import { ArrowLeftIcon } from "components/svgs/ArrowLeftIcon";
import { ArrowRightIcon } from "components/svgs/ArrowRightIcon";
import React, { useState, useCallback, useContext, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { GroupIcon, OrderIcon, HistoryIcon } from "../../../components/svgs";
import "./ClientSiderContent.scss";
import { ReactSVG } from "react-svg";
import { ProfileContext } from "layouts/private/ProfileContext";
import { Link } from "react-router-dom";
import UserInfo from "../Users/UserInfo";
import { BarChartIcon } from "components/svgs/menu/BarChartIcon";
import { RoutesIcon } from "components/svgs/menu/RoutesIcon";
import { LocationsIcon } from "components/svgs/menu/LocationsIcon";
import { DriversIcon } from "components/svgs/menu/DriversIcon";
import { NeedHelpIcon } from "components/svgs/menu/NeedHelpIcon";

const { Sider } = Layout;
const { SubMenu } = Menu;
const getPathName = (str) => {
  let rs = "/";
  const path = str && str[str.length - 1] == "/" ? str.substring(0, str.length - 1) : str;
  switch (path) {
    case "/home":
      rs = "/home";
      break;
    case "/dashboard":
      rs = "/dashboard";
      break;
    case "/routes":
      rs = "/routes";
      break;
    case "/pool":
      rs = "/pool";
      break;
    case "/locations":
      rs = "/locations";
      break;
    case "/history":
      rs = "/history";
      break;
    case "/client-users":
      rs = "/client-users";
      break;
    case "/drivers":
      rs = "/drivers";
      break;
  }
  return rs;
};

const ClientSiderContent = () => {
  const location = useLocation();
  const pathname = getPathName(getPathName(location.pathname));
  console.log({ pathname });

  const [siderKey, setSiderKey] = useState(pathname);
  const [openKey, setOpenKey] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const profileCtx = useContext(ProfileContext);
  const profile = useMemo(() => profileCtx.profile);

  const handleClickCollapse = () => {
    setCollapsed(!collapsed);
  };

  const history = useHistory();

  const handleClickMenuItem = useCallback(
    (value) => {
      const key = value?.key;
      setSiderKey(getPathName(key));
      setOpenKey([]);
      key && history.push(`${key}`);
    },
    [setSiderKey, setOpenKey, history]
  );

  const handleClickMenuSub = useCallback(
    (value) => {
      const newOpenKeys = [...openKey];
      if (newOpenKeys.includes(value.key)) {
        const index = newOpenKeys.findIndex((key) => key === value.key);
        delete newOpenKeys[index];
        setOpenKey(newOpenKeys);
      } else {
        newOpenKeys.push(value.key);
        setOpenKey(newOpenKeys);
      }
    },
    [openKey]
  );
  return (
    <Sider
      className="client-sider-content-component"
      trigger={null}
      collapsible
      width={228}
      collapsed={collapsed}
    >
      <Link to="/">
        <Image
          src={`/logos/${window.REACT_APP_LOGO_FILE_NAME}`}
          alt="logo"
          preview={false}
          width={130}
          style={{ margin: "0 0 4em 2em" }}
        />
      </Link>
      <Menu
        mode="inline"
        openKeys={openKey}
        selectedKeys={[siderKey]}
        onClick={handleClickMenuItem}
      >
        {false && (
          <Menu.Item
            key="/home"
            icon={
              <span className="anticon">
                <HomeOutlined />
              </span>
            }
          >
            Home
          </Menu.Item>
        )}
        <Menu.Item
          key="/dashboard"
          icon={
            <span className="anticon">
              <BarChartIcon />
            </span>
          }
        >
          Dashboard
        </Menu.Item>
        {window.RESELLER_FLAG && (
          <>
            {profile?.isReseller && (
              <Menu.Item
                key="/reseller/dashboard"
                icon={
                  <span className="anticon">
                    <LineChartOutlined />
                  </span>
                }
              >
                Reseller
              </Menu.Item>
            )}
          </>
        )}
        <Menu.Item
          key="/routes"
          icon={
            <span className="anticon">
              <RoutesIcon />
            </span>
          }
        >
          Routes
        </Menu.Item>
        <Menu.Item
          key="/pool"
          icon={
            <span className="anticon">
              <GiftOutlined />
            </span>
          }
        >
          Flexio Pool
        </Menu.Item>
        <Menu.Item
          key="/locations"
          icon={
            <span className="anticon">
              <LocationsIcon />
            </span>
          }
        >
          Locations
        </Menu.Item>
        <Menu.Item
          key="/history"
          icon={
            <span className="anticon">
              <HistoryIcon />
            </span>
          }
        >
          History
        </Menu.Item>
        <Menu.Item
          key="/client-users"
          icon={
            <span className="anticon">
              <UserOutlined />
            </span>
          }
        >
          Users
        </Menu.Item>
        <Menu.Item
          key="/drivers"
          icon={
            <span className="anticon">
              <DriversIcon />
            </span>
          }
        >
          My Drivers
        </Menu.Item>
        <SubMenu
          key="need-help"
          icon={
            <span className="anticon">
              <NeedHelpIcon />
            </span>
          }
          title="Need help?"
          onTitleClick={handleClickMenuSub}
        >
          <Menu.Item key="/" icon={<span className="iconClass operation-icon"></span>}>
            <a
              href="https://support.goflexio.com"
              style={{ color: "#9197b3" }}
              target="_blank"
              rel="noreferrer"
            >
              Help Center
            </a>
          </Menu.Item>
          <Menu.Item key="/" icon={<span className="iconClass mapIcon"></span>}>
            <a
              href="https://youtu.be/TaI-YPzOygM"
              style={{ color: "#9197b3" }}
              target="_blank"
              rel="noreferrer"
            >
              Tutorial Video
            </a>
          </Menu.Item>
          <Menu.Item key="/" icon={<span className="iconClass mapIcon"></span>}>
            <a
              href="https://meetings.hubspot.com/iraymond/route-upload-support"
              style={{ color: "#9197b3" }}
              target="_blank"
              rel="noreferrer"
            >
              Talk to a Specialist
            </a>
          </Menu.Item>
        </SubMenu>
        <div style={{ position: "absolute", bottom: 20, left: 0 }}>
          <UserInfo isUser={true} user={profile} />
        </div>
      </Menu>
      <div className="button-collapse" onClick={handleClickCollapse}>
        <span>{collapsed ? <ArrowRightIcon /> : <ArrowLeftIcon />}</span>
      </div>
    </Sider>
  );
};
export default ClientSiderContent;
