import "../../styles/settings.scss";
import { CheckCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Checkbox,
  Tag,
  Row,
  Col,
  Modal,
  notification,
  Switch
} from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { convertHexToRGBA, formatVehicleCategories } from "../../../../../helper/util";
import { useHistory } from "react-router-dom";
import { doCreateService, doUpdateService } from "../../store/ServiceThunks";
import { getAllVehicleCategories } from "../../store/VehicleCategoryThunks";
import { selectAllVehicleCategories } from "../../store/VehicleCategorySlice";
import { getAllPackageType } from "../../store/PackageTypeThunks";
import PolygonMapboxForm from "./PolygonMapboxForm";
import { DEFAULT_CENTER } from "configs/constants";
import RatesPerVehicleCategoryFields from "../fields/RatesPerVehicleCategoryFields";
import { EditOutlined } from "@ant-design/icons";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";
import _groupBy from "lodash/groupBy";
import _uniq from "lodash/uniq";
import { VEHICLE_RATE_TYPES } from "configs/constants";
import { selectAllPackageTypes } from "../../store/PackageTypeSlice";

const ServiceForm = ({ service }) => {
  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();
  const isLoading = useSelector((state) => state.global.isLoading);
  const history = useHistory();
  const vehicleCategories = useSelector(selectAllVehicleCategories);
  const [packageTypesValue, setPackageTypesValue] = useState([]);
  const packageTypes = useSelector(selectAllPackageTypes);
  const [pickupCoords, setPickupCoords] = useState(service?.polygon || []);
  const [deliveryCoords, setDeliveryCoords] = useState(service?.polygon || []);
  const [center, _] = useState(DEFAULT_CENTER);
  const [isEditable, setEditable] = useState(false);
  //const [returnToPickupLocation, setReturnToPickupLocation] = useState(false);
  const [clientRates, setClientRates] = useState({});
  const [driverRates, setDriverRates] = useState({});
  const [showRatesModal, setShowRatesModal] = useState(false);
  const [vehicleCategoriesRates, setVehicleCategoriesRates] = useState([]);
  const [ratesModalInfo, setRatesModalInfo] = useState([]);
  const [editRatesMode, setEditRatesMode] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const handleCancel = () => {
    history.push("/admin/settings/services");
  };
  console.log(clientRates);
  const onSubmit = async (values) => {
    if (!isValidPackageTypes(packageTypesValue)) return;

    const payload = {
      ...values,
      vehicleRates: formatVehicleCategories(vehicleCategoriesRates),
      packageTypes: packageTypesValue.map((packageTypeId) => ({
        id: packageTypeId
      })),
      pickupCoords,
      deliveryCoords,
      //returnToPickupLocation,
      isDefault: values?.isDefault ?? false
    };

    setSubmitted(true);
    if (!isValidPayload(payload)) return;

    try {
      let erRs = undefined;

      if (!service) {
        const { error } = await dispatch(doCreateService(payload));
        erRs = error;
      } else {
        const { error } = await dispatch(
          doUpdateService({
            ...payload,
            id: service.id
          })
        );
        erRs = error;
      }
      if (!erRs) history.push("/admin/settings/services");
    } catch (err) {
      console.log(err);
    }
  };

  function isValidPayload(payload = {}) {
    return (
      !_isEmpty(payload.vehicleRates) &&
      !_isEmpty(payload.pickupCoords) &&
      !_isEmpty(payload.deliveryCoords) &&
      !_isEmpty(payload.packageTypes)
    );
  }

  /*
  function onChangeReturnToPickupLocation(checkedValues) {
    setReturnToPickupLocation(checkedValues.target.checked);
  }
  */

  useEffect(() => {
    if (!_isEmpty(service)) {
      if (service.vehicleRates) {
        const vehicleRateGroup = _groupBy(service.vehicleRates, "vehicleCategoryId");
        setVehicleCategoriesRates(
          Object.keys(vehicleRateGroup).map((vehicleCategoryId) => ({
            id: vehicleCategoryId,
            clientRates: _find(vehicleRateGroup[vehicleCategoryId], {
              type: VEHICLE_RATE_TYPES.CLIENT_RATE
            }),
            driverRates: _find(vehicleRateGroup[vehicleCategoryId], {
              type: VEHICLE_RATE_TYPES.DRIVER_RATE
            })
          }))
        );
      }
      setPackageTypesValue(service.packageTypes?.map((packageType) => packageType.id));
      setPickupCoords(service.pickupCoords);
      setDeliveryCoords(service.deliveryCoords);
      //setReturnToPickupLocation(service.returnToPickupLocation);
      form.setFieldsValue(service);
    }
  }, [service, form]);

  useEffect(() => {
    dispatch(getAllVehicleCategories());
    dispatch(getAllPackageType());
  }, [dispatch]);

  function isValidPackageTypes(packageTypeList = []) {
    const selectedVehicleCatagoryIds =
      vehicleCategoriesRates.map((item) => item.id) || [];
    const acceptedPackageTypeIds = _uniq(
      vehicleCategories
        ?.filter((item) => selectedVehicleCatagoryIds.includes(item.id))
        ?.reduce((pre, cur) => [...pre, ...cur?.packageTypes], [])
        ?.map((packageType) => packageType.id)
    );

    for (const packageTypeId of packageTypeList) {
      if (!acceptedPackageTypeIds.includes(packageTypeId)) {
        notification.warning({
          message: "Package type must be belonged to selected vehicle categories"
        });
        return false;
      }
    }
    return true;
  }

  function onChangePackageTypes(checkedValues = []) {
    if (packageTypesValue?.length < checkedValues?.length) {
      if (!isValidPackageTypes(checkedValues)) return;
    }
    setPackageTypesValue(checkedValues);
  }

  function onChangeVehicleCategories(e) {
    const vehicleCategoryID = e.target.value;
    const isChecked = e.target.checked;

    if (!isChecked) {
      const updatedVehicleCategoriesRate = vehicleCategoriesRates.filter(
        (item) => item.id !== vehicleCategoryID
      );

      setVehicleCategoriesRates(updatedVehicleCategoriesRate);
      setClientRates({});
      setDriverRates({});
      return;
    }

    const vehicleData = vehicleCategories.find(
      (vehicle) => vehicle.id === vehicleCategoryID
    );

    setRatesModalInfo({ ...vehicleData });
    setShowRatesModal(!showRatesModal);
  }

  const onCompletePickupDraw = (coordinates) => {
    setPickupCoords(coordinates);
    form.setFieldsValue({ polygon: coordinates });
    setEditable(false);
  };

  const onCompleteDeliveryDraw = (coordinates) => {
    setDeliveryCoords(coordinates);
    form.setFieldsValue({ polygon: coordinates });
    setEditable(false);
  };

  const onSubmitModalForm = () => {
    if (editRatesMode) {
      editVehicleRates();
      return;
    }

    const newVehicleCategoriesData = { id: ratesModalInfo.id, clientRates, driverRates };

    setVehicleCategoriesRates([...vehicleCategoriesRates, newVehicleCategoriesData]);
    setClientRates({});
    setDriverRates({});
    setShowRatesModal(false);
  };

  const ratesHandleCancel = () => {
    setClientRates({});
    setDriverRates({});
    setRatesModalInfo({});
    setShowRatesModal(false);
  };

  const showEditVehicleRatesModal = (vehicleID) => {
    setShowRatesModal(true);
    setEditRatesMode(true);

    const vehicleCategoryRate = vehicleCategoriesRates.find(
      (item) => item.id === vehicleID
    );

    const vehicleData = vehicleCategories.find((vehicle) => vehicle.id === vehicleID);

    setRatesModalInfo({ ...vehicleData });

    setClientRates({ ...vehicleCategoryRate.clientRates });
    setDriverRates({ ...vehicleCategoryRate.driverRates });
  };

  const editVehicleRates = () => {
    const newVehicleCategoriesData = [];

    for (let i = 0; i < vehicleCategoriesRates.length; i++) {
      const vcr = vehicleCategoriesRates[i];

      if (vcr.id === ratesModalInfo.id) {
        continue;
      }

      newVehicleCategoriesData.push(vcr);
    }

    let newVehicleData = { id: ratesModalInfo.id, clientRates, driverRates };

    setVehicleCategoriesRates([...newVehicleCategoriesData, newVehicleData]);

    setShowRatesModal(false);
    setEditRatesMode(false);
  };

  return (
    <div className="common-form split-two-form">
      <Form
        scrollToFirstError={true}
        layout="vertical"
        hideRequiredMark
        colon={false}
        onFinish={onSubmit}
        form={form}
      >
        {/* <div className="switcher-btn mb-3">
          <Form.Item valuePropName="checked" name="isDefault">
            <Switch />
          </Form.Item>
          <span> &nbsp;Is Default</span>
        </div> */}
        <Form.Item
          label="Service Name"
          name="name"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input size="large" placeholder="Service Name" />
        </Form.Item>
        <Form.Item
          label="Service Description"
          name="description"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input size="large" placeholder="Service Description" />
        </Form.Item>
        <h2 className="title select-package-types-title mt-3 mb-0">
          Service Bullet Points
        </h2>
        <div className="form-content">
          <Form.Item
            label="Bullet #1"
            name="bulletPointOne"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input size="large" placeholder="Bullet #1" />
          </Form.Item>
          <Form.Item
            label="Bullet #2"
            name="bulletPointTwo"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input size="large" placeholder="Bullet #2" />
          </Form.Item>
          <Form.Item
            label="Bullet #3"
            name="bulletPointThree"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input size="large" placeholder="Bullet #3" />
          </Form.Item>
        </div>
        <h2 className="title select-package-types-title mt-3">
          View Pickup and Delivery Zones
        </h2>
        <Row gutter={24}>
          <Col span={12}>
            <h2>Pickup Geofence</h2>
            {submitted && _isEmpty(pickupCoords) && (
              <div role="alert" className="ant-form-item-explain-error">
                Required
              </div>
            )}
            <PolygonMapboxForm
              showTitle={false}
              onCompleteDraw={onCompletePickupDraw}
              coords={pickupCoords}
              isEditable={isEditable}
              setEditable={setEditable}
              center={center}
            />
          </Col>
          <Col span={12}>
            <h2>Delivery Geofence</h2>
            {submitted && _isEmpty(deliveryCoords) && (
              <div role="alert" className="ant-form-item-explain-error">
                Required
              </div>
            )}
            <PolygonMapboxForm
              showTitle={false}
              onCompleteDraw={onCompleteDeliveryDraw}
              coords={deliveryCoords}
              isEditable={isEditable}
              setEditable={setEditable}
              center={center}
            />
          </Col>
        </Row>
        <div className="mt-4">
          {/*
          <Form.Item label="">
            <Checkbox
              checked={returnToPickupLocation}
              onChange={onChangeReturnToPickupLocation}
            >
              Return to Pickup location at end
            </Checkbox>
          </Form.Item>
            */}
          <h2 className="title select-package-types-title mt-3">Terms and Conditions</h2>
          <Form.Item
            label="T&C Link"
            name="termsLink"
            rules={[{ type: "url", message: "Invalid URL" }]}
          >
            <Input size="large" placeholder="T&C Link" />
          </Form.Item>
        </div>
        <h2 className="title select-vehicle-categories-title">
          Select Vehicle Categories w/ Parameters
        </h2>
        {submitted && _isEmpty(vehicleCategoriesRates) && (
          <div role="alert" className="ant-form-item-explain-error">
            Required
          </div>
        )}
        {vehicleCategories.map((vehicleCategory) => {
          return (
            <div className="group-check-box-with-description" key={vehicleCategory.id}>
              <Checkbox
                className="check-box-with-description"
                value={vehicleCategory.id}
                onChange={onChangeVehicleCategories}
                checked={vehicleCategoriesRates.find((i) => i.id === vehicleCategory.id)}
              >
                {vehicleCategory.name}
              </Checkbox>
              <div className="three-description mt-2">
                <div>
                  Max Length:{" "}
                  <b>
                    {vehicleCategory.maxLength}{" "}
                    {window.REACT_APP_DD_ENV == "ccpexpress" ? "cm" : "inches"}
                  </b>
                </div>
                <div>
                  Max Weight:{" "}
                  <b>
                    {vehicleCategory.maxWeight}{" "}
                    {window.REACT_APP_DD_ENV == "ccpexpress" ? "kg" : "lbs"}
                  </b>
                </div>
                <div>
                  Max Volume:{" "}
                  <b>
                    {vehicleCategory.maxVolume}{" "}
                    {window.REACT_APP_DD_ENV == "ccpexpress" ? <>&#13221;</> : "cuft"}
                  </b>
                </div>
              </div>
              <div className="tag-description">
                {vehicleCategory.packageTypes &&
                  vehicleCategory.packageTypes.map((packageType) => (
                    <Tag
                      key={packageType.id}
                      color={convertHexToRGBA("#f4f4f4", 100)}
                      style={{
                        color: "#000000",
                        lineHeight: "24px",
                        textAlign: "center",
                        borderRadius: "10px"
                      }}
                    >
                      {packageType.name}
                    </Tag>
                  ))}
                {!!vehicleCategoriesRates.length &&
                  vehicleCategoriesRates.find((vehicleCategoryRate) => {
                    return vehicleCategoryRate.id === vehicleCategory.id;
                  }) && (
                    <a
                      href="#"
                      className="float-right mr-3 mt-1"
                      onClick={() => showEditVehicleRatesModal(vehicleCategory.id)}
                    >
                      <EditOutlined />
                      Edit rates
                    </a>
                  )}
              </div>
            </div>
          );
        })}
        {/* </Checkbox.Group> */}
        <Modal
          title={`${ratesModalInfo.name} Rates`}
          visible={showRatesModal}
          // onOk={ratesHandleOk}
          onCancel={ratesHandleCancel}
          width={"80%"}
          okText=""
          cancelText=""
          footer={null}
          id={ratesModalInfo.id}
        >
          <Form onFinish={onSubmitModalForm} form={modalForm}>
            <Row>
              <Col span={12}>
                <RatesPerVehicleCategoryFields
                  data={clientRates}
                  setData={setClientRates}
                  title="Vehicle Client Rates"
                />
              </Col>
              <Col span={12}>
                <RatesPerVehicleCategoryFields
                  data={driverRates}
                  setData={setDriverRates}
                  title="Vehicle Driver Rates"
                />
              </Col>
            </Row>
            <div className="d-flex" style={{ justifyContent: "end" }}>
              <Button className="m-1" onClick={ratesHandleCancel}>
                Cancel
              </Button>
              <Button className="m-1" htmlType="submit" type="primary">
                Submit
              </Button>
            </div>
          </Form>
        </Modal>
        <h2 className="title select-package-types-title mt-3">
          Select Package Types w/ Parameters
        </h2>
        {submitted && _isEmpty(packageTypesValue) && (
          <div role="alert" className="ant-form-item-explain-error">
            Required
          </div>
        )}
        <Checkbox.Group
          className="form-content"
          onChange={onChangePackageTypes}
          value={packageTypesValue}
        >
          {packageTypes.map((packageType) => {
            return (
              <div className="group-check-box-with-description" key={packageType.id}>
                <Checkbox className="check-box-with-description" value={packageType.id}>
                  {packageType.name}
                </Checkbox>
                <div className="two-description mt-2">
                  <div>
                    Max Weight: <b>{packageType.maxWeight}</b>
                  </div>
                  <div>
                    Max Volume: <b>{packageType.maxVolume}</b>
                  </div>
                  <div>
                    Max Length: <b>{packageType.maxLength}</b>
                  </div>
                </div>
              </div>
            );
          })}
        </Checkbox.Group>

        <div className="line-break"></div>
        <div className="form-footer">
          <Button loading={isLoading} onClick={handleCancel}>
            Cancel
          </Button>
          <Button loading={isLoading} type="primary" htmlType="submit">
            {service ? "Update" : "Create"}
            <CheckCircleOutlined />
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ServiceForm;
