import React from "react";

export const NeedHelpIcon = () => {
  return (
    <svg
      width="12"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0C2.75391 0 0 2.29102 0 5.25V7.5H4.5V5.25C4.5 5.13867 4.56738 4.97754 4.82812 4.80469C5.08887 4.63184 5.51367 4.5 6 4.5C6.48926 4.5 6.91406 4.63184 7.17188 4.80469C7.42969 4.97754 7.5 5.12695 7.5 5.25C7.5 5.68359 7.35938 5.98535 7.07812 6.32812C6.79688 6.6709 6.36035 7.02539 5.88281 7.42969C4.9248 8.24121 3.75 9.37207 3.75 11.25V12H8.25V11.25C8.25 10.9951 8.34375 10.8164 8.625 10.5234C8.90625 10.2305 9.37207 9.88477 9.86719 9.46875C10.8574 8.63672 12 7.3623 12 5.25C12 2.31738 9.25195 0 6 0ZM6 1.5C8.5459 1.5 10.5 3.27539 10.5 5.25C10.5 6.8584 9.76758 7.57617 8.88281 8.32031C8.44043 8.69238 7.96875 9.05566 7.54688 9.49219C7.28613 9.76172 7.08398 10.1133 6.9375 10.5H5.48438C5.71875 9.74121 6.17578 9.16406 6.86719 8.57812C7.32715 8.19141 7.82812 7.78711 8.25 7.26562C8.67188 6.74414 9 6.06152 9 5.25C9 4.5293 8.57227 3.93164 8.01562 3.5625C7.45898 3.19336 6.75293 3 6 3C5.24414 3 4.53809 3.19336 3.98438 3.5625C3.43066 3.93164 3 4.53223 3 5.25V6H1.5V5.25C1.5 3.2373 3.44824 1.5 6 1.5ZM3.75 13.5V18H8.25V13.5H3.75ZM5.25 15H6.75V16.5H5.25V15Z"
        fill="#9197B3"
      />
    </svg>
  );
};
