import { Button, Carousel, Form, Image, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import React from "react";

import { handleLogin } from "../store/thunk";
import { withAuthUser } from "../hocs/withAuth";
import laptopImg from "../../../images/flexio-2.0/laptop-map.png";

import "./LoginPage.scss";
import { CheckCircleFilled } from "@ant-design/icons";

const LoginPage = () => {
  const isLoading = useSelector((state) => state.global.isLoading);
  const dispatch = useDispatch();
  const onSubmit = (values) => {
    dispatch(handleLogin(values));
  };

  return (
    <div className="login-page">
      <div className="wrap-page d-flex">
        <div
          className="image-logo-m"
          style={{
            backgroundSize: "100% auto"
          }}
        >
          <img src={`/logos/user/${window.REACT_APP_LOGO_FILE_NAME}`} />
          <img
            id="bus-icon"
            src="https://cdn-icons-png.freepik.com/256/14145/14145374.png?ga=GA1.1.1656896880.1708450789&semt=ais"
            alt="flexio delivery"
          />
        </div>
        <div className="image-logo-m" style={{ display: "none" }}>
          <img
            className="image-logo-m"
            src={`/logos/user/${window.REACT_APP_LOGO_FILE_NAME}`}
          />
        </div>

        <div className="main-container">
          <div className="form">
            <Form
              scrollToFirstError={true}
              layout="vertical"
              hideRequiredMark
              colon={false}
              onFinish={onSubmit}
            >
              <div className="form-header">
                <div className="text-center">
                  <Image
                    src={`/logos/${window.REACT_APP_LOGO_FILE_NAME}`}
                    className="img-fluid"
                    width={150}
                    preview={false}
                  />
                </div>
                <div>
                  {(() => {
                    if (window.REACT_APP_DD_ENV === "ccpexpress") {
                      return <p>Login to your CCP account.</p>;
                    } else {
                      return <p>Login to your Flexio account.</p>;
                    }
                  })()}
                </div>
              </div>
              <div>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { type: "email", message: "Invalid Email" },
                    { required: true, message: "Required" }
                  ]}
                >
                  <Input size="large" placeholder="name@email.com" />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: "Required" }]}
                  className="input-password"
                >
                  <Input.Password
                    size="large"
                    placeholder="8+ Characters, 1 Capital letter"
                  />
                </Form.Item>
              </div>
              <Link to="/forgot-password">
                <div className="forgot-password">Forgot Password?</div>
              </Link>

              <div className="form-footer">
                <Button loading={isLoading} type="primary" htmlType="submit" block>
                  Log In
                </Button>
                <div>
                  <p>Don’t have an account? </p>
                  <Link to="/register" className="sign-up">
                    Sign Up
                  </Link>
                </div>
              </div>
            </Form>
            <p className="website-text">
              {(() => {
                if (window.REACT_APP_DD_ENV == "ccpexpress") {
                  return (
                    <>
                      <a
                        href="https://www.ccplogistics.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        ccplogistics.com
                      </a>
                    </>
                  );
                } else {
                  return (
                    <>
                      <a href="https://www.goflexio.com" target="_blank" rel="noreferrer">
                        goflexio.com
                      </a>
                    </>
                  );
                }
              })()}
            </p>
          </div>
          <div className="image-container">
            <Carousel dots draggable infinite arrows autoplay>
              <div className="image-wrapper">
                <Image
                  src={laptopImg}
                  width={380}
                  height={380}
                  className="img-fluid"
                  style={{ position: "absolute", zIndex: 999, bottom: "-100px" }}
                  preview={false}
                />
                <div className="blue-circle"></div>
              </div>
              <div className="benefits-wrapper">
                <div className="benefits-container">
                  {(() => {
                    if (window.REACT_APP_DD_ENV == "ccpexpress") {
                      return (
                        <>
                          <h2 className="benefits-title">
                            CCP Express Powered by Flexio. Benefits include:
                          </h2>
                          <div className="benefits-list">
                            <div className="benefits-list-item">
                              <CheckCircleFilled size={10} className="benefits-icon" />
                              <p>Worldwide routing planning.</p>
                            </div>
                            <div className="benefits-list-item">
                              <CheckCircleFilled size={10} className="benefits-icon" />
                              <p>Real-time tracking with SMS.</p>
                            </div>
                            <div className="benefits-list-item">
                              <CheckCircleFilled size={10} className="benefits-icon" />
                              <p>Fast and safe deliveries.</p>
                            </div>
                          </div>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <h2 className="benefits-title">
                            What do your clients gain by using Flexio Pool in your Shopify
                            store?
                          </h2>
                          <div className="benefits-list">
                            <div className="benefits-list-item">
                              <CheckCircleFilled size={10} className="benefits-icon" />
                              <p>Transparent rates at checkout.</p>
                            </div>
                            <div className="benefits-list-item">
                              <CheckCircleFilled size={10} className="benefits-icon" />
                              <p>Real-time tracking with SMS.</p>
                            </div>
                            <div className="benefits-list-item">
                              <CheckCircleFilled size={10} className="benefits-icon" />
                              <p>Fast and safe deliveries throughout the island.</p>
                            </div>
                          </div>
                        </>
                      );
                    }
                  })()}
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withAuthUser(LoginPage);
