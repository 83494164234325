import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useState } from "react";
import ReactMapboxGl from "react-mapbox-gl";
import { isArrayEmpty } from "../store/utils";
import { setMapboxRTLTextPlugin } from "helper/util";
import { CCP_DEFAULT_CENTER } from "configs/constants";
import { DEFAULT_CENTER } from "configs/constants";

mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
setMapboxRTLTextPlugin(mapboxgl);
const Map = ReactMapboxGl({
  minZoom: 8,
  accessToken: window.REACT_APP_MAPBOX_KEY
});

const defaultCenter =
  window.REACT_APP_DD_ENV === "ccpexpress"
    ? {
        lat: CCP_DEFAULT_CENTER[1],
        lng: CCP_DEFAULT_CENTER[0]
      }
    : {
        lat: DEFAULT_CENTER[1],
        lng: DEFAULT_CENTER[0]
      };
const zoomLevel = 12;

export default function RoutingMap({ children, bounds, center }) {
  const [fitBounds, setFitBounds] = useState(isArrayEmpty(bounds) ? undefined : bounds);

  useEffect(() => {
    if (isArrayEmpty(bounds)) return;
    const ref = setTimeout(() => {
      setFitBounds(bounds);
    }, 1000);
    return () => clearTimeout(ref);
  }, [bounds]);

  return (
    <Map
      zoom={[zoomLevel]}
      center={center || defaultCenter}
      style="mapbox://styles/mapbox/streets-v11"
      movingMethod="easeTo"
      fitBounds={fitBounds}
      fitBoundsOptions={{ padding: fitBounds?.length > 0 ? 80 : undefined }}
    >
      {children}
    </Map>
  );
}
