import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { selectAuthentication, selectGroup } from "../modules/auth/store/authSlice";
import MainLayout from "../layouts/private/MainLayout";
import { UserGroup } from "configs/constants";
import { ProfileProvider } from "layouts/private/ProfileContext";
import { FeatureFlagsProvider } from "layouts/private/FeatureFlagsContext";
import Cookies from "universal-cookie";
import { TOKEN_NAME } from "configs/constants";
import { removeAmazonConnectChatElement } from "helper/util";

const PrivateUserRoute = (props) => {
  const { component: Component, isFullScreen = false, ...rest } = props;
  const authentication = useSelector(selectAuthentication);
  const group = useSelector(selectGroup);
  const cookies = new Cookies();

  useEffect(() => {
    loadClientAmazonConnectChat();
  }, [cookies]);

  const loadClientAmazonConnectChat = () => {
    const token = cookies.get(TOKEN_NAME);
    if (!token) {
      removeAmazonConnectChatElement();
      return;
    }
    const script = document.createElement("script");
    script.innerHTML = `
      (function(w, d, x, id){
      s=d.createElement('script');
      s.src='https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js';
      s.async=1;
      s.id=id;
      d.getElementsByTagName('head')[0].appendChild(s);
      w[x] =  w[x] || function() { (w[x].ac = w[x].ac || []).push(arguments) };
    })(window, document, 'amazon_connect', '2bca152f-32ff-4473-b543-fa212fb75128');
    amazon_connect('styles', { iconType: 'CHAT', openChat: { color: '#ffffff', backgroundColor: '#004ebb' }, closeChat: { color: '#ffffff', backgroundColor: '#004ebb'} });
    amazon_connect('snippetId', 'QVFJREFIaWFZYXRVSlpIekdkUUg5YXhZenVQMktKRXNIWTVFQWpBYVErTEdzRnpvZHdHUDdyTHByaXh0NjFkVkJPQ1EreVo3QUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNeVB4bndhenJneHdrc1ZKTUFnRVFnQ3ZlRUV4MVFhRnNkVEx5Z0poMlhaY1RvaWxBeWgyRnlBbm1EVkFPRGhHblJlWi8vYVQwMmg1OTNuWjE6OjF4Z2lTS0NxZFdhMytRaVJ2YUk1bk01OW9sUG5Hay95MG9BWGFpelBubnhONGJYVXY1c1dURlF4U3RqRVo1cDM0dFRNZlMwcDlYaERQeGhMUVdQOGt4K1kvL28zaTIwK2NxMk1uTER2cDNZMkpOdjBMSzc4SVdSbkF2cXVWNjdSNHNGejhFS09aWWc2TEZPVUt5R09CRUdNYXZOK284az0=');
    amazon_connect('supportedMessagingContentTypes', [ 'text/plain', 'text/markdown', 'application/vnd.amazonaws.connect.message.interactive', 'application/vnd.amazonaws.connect.message.interactive.response' ]);
  `;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  if (!authentication || group !== UserGroup.CLIENT) {
    return (
      <Redirect
        to={{
          pathname: `/login`
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(propsCom) => {
        return (
          <FeatureFlagsProvider>
            <ProfileProvider>
              <MainLayout isFullScreen={isFullScreen}>
                <Component {...propsCom} />
              </MainLayout>
            </ProfileProvider>
          </FeatureFlagsProvider>
        );
      }}
    />
  );
};
export default PrivateUserRoute;
