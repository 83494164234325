import React from "react";
export const BarChartIcon = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.75 0V18H19.75V0H13.75ZM15.25 1.5H18.25V16.5H15.25V1.5ZM0.25 4.5V18H6.25V4.5H0.25ZM1.75 6H4.75V16.5H1.75V6ZM7 9V18H13V9H7ZM8.5 10.5H11.5V16.5H8.5V10.5Z"
        fill="#9197B3"
      />
    </svg>
  );
};
