import { PHONE_VALIDATION } from "configs/constants";
import { NAME_REGEX } from "configs/constants";
import * as yup from "yup";
export const step1Schema = yup.object().shape({
  firstName: yup
    .string()
    .required("First name is required")
    .matches(NAME_REGEX, "Invalid First Name")
    .nullable(),
  lastName: yup
    .string()
    .required("Last name is required")
    .matches(NAME_REGEX, "Invalid Last Name")
    .nullable(),
  email: yup.string().email().required("Email is required").nullable(),
  phone: yup.string().required("Phone is required").nullable(),
  country: yup.string().required("Country is required").nullable(),
  state: yup.string().when("country", {
    is: "US",
    then: yup.string().required("State is required"),
    otherwise: yup.string().nullable()
  }),
  active: yup.bool().required("Please choose active user").nullable(),
  emergencyContactPhone: yup.string().required("Emergency Contact Phone is required"),
  emergencyContactName: yup.string().required("Emergency Contact is required").nullable(),
  serviceProvider: yup.mixed().required("Phone Service Provider is required").nullable(),
  phoneType: yup.mixed().required("Phone Type is required").nullable()
});

export const step2Schema = yup.object().shape({
  state: yup.string().optional().nullable(),
  city: yup.string().optional().nullable(),
  postalAddress: yup.string().optional().nullable(),
  profilePhoto: yup
    .object()
    .shape({
      url: yup.string().required().nullable(),
      id: yup.string().required().nullable(),
      name: yup.string().required().nullable()
    })
    .optional()
    .nullable(),
  driverLicense: yup
    .object()
    .shape({
      url: yup.string().required().nullable(),
      id: yup.string().required().nullable(),
      name: yup.string().required().nullable()
    })
    .optional()
    .nullable(),
  drivingRecord: yup
    .object()
    .shape({
      url: yup.string().required().nullable(),
      id: yup.string().required().nullable(),
      name: yup.string().required().nullable()
    })
    .optional()
    .nullable(),
  criminalRecordCertificate: yup
    .object()
    .shape({
      url: yup.string().required().nullable(),
      id: yup.string().required().nullable(),
      name: yup.string().required().nullable()
    })
    .optional()
    .nullable(),
  taxSsnOrEin: yup.string().optional().nullable(),
  taxName: yup.string().optional().nullable(),
  taxAddress: yup.string().optional().nullable()
});
export const step2SchemaWithoutState = yup.object().shape({
  city: yup.string().optional().nullable(),
  postalAddress: yup.string().optional().nullable(),
  licenseExpirationDate: yup.string().optional().nullable(),
  recordExpirationDate: yup.string().optional().nullable(),
  recordCertificateDate: yup.string().optional().nullable(),
  profilePhoto: yup.object().optional().nullable(),
  driverLicense: yup.object().optional().nullable(),
  drivingRecord: yup.object().optional().nullable(),
  criminalRecordCertificate: yup
    .object()
    .shape({
      url: yup.string().required().nullable(),
      id: yup.string().required().nullable(),
      name: yup.string().required().nullable()
    })
    .optional()
    .nullable(),
  taxSsnOrEin: yup.string().optional().nullable(),
  taxName: yup.string().optional().nullable(),
  taxAddress: yup.string().optional().nullable()
});
export const step3Schema = yup.object().shape({
  fullNameBank: yup.string().optional().nullable(),
  emailBank: yup.string().email().optional().nullable(),
  bankName: yup.string().optional().nullable(),
  bankAccountNumber: yup.string().optional().nullable(),
  bankAccountRoutingNumber: yup
    .string()
    .required("Bank Account Routing Number is required")
    .nullable(),
  accountType: yup.string().optional().nullable()
});

export const step4Schema = yup.object().shape({
  haveInsurance: yup.string().optional().nullable(),
  haveLicenseCertification: yup.string().optional().nullable(),
  haveTransportationDelivery: yup.string().optional().nullable(),
  rateUsingMobile: yup.string().optional().nullable(),
  understandLanguages: yup.array().optional().nullable(),
  additionalRelevantExperiences: yup.string().optional().nullable(),
  learnFlexio: yup.array().optional().nullable()
});

export const additionalVehicleSchema = yup.object().shape({
  vehicleTypes: yup
    .array()
    .required("Vehicle Types is required")
    .min(1, "Vehicle Types is required"),
  vehicleMake: yup.string().required("Vehicle make is required").nullable(),
  vehicleModel: yup.string().required("Vehicle model is required").nullable(),
  vehicleColor: yup.string().required("Vehicle color is required").nullable(),
  vehicleYear: yup.string().required("Vehicle year is required").nullable(),
  numberPlate: yup.string().required("Number plate is required").nullable(),
  vehicleLicensePlate: yup
    .object()
    .shape({
      url: yup.string().required().nullable(),
      id: yup.string().required().nullable(),
      name: yup.string().required().nullable()
    })
    .required("Vehicle License Plate is required")
    .nullable(),
  vehicleImageLeftSide: yup
    .object()
    .shape({
      url: yup.string().required().nullable(),
      id: yup.string().required().nullable(),
      name: yup.string().required().nullable()
    })
    .required("Vehicle Image Left Side is required")
    .nullable(),
  vehicleImageRightSide: yup
    .object()
    .shape({
      url: yup.string().required().nullable(),
      id: yup.string().required().nullable(),
      name: yup.string().required().nullable()
    })
    .required("Vehicle Image Right Side is required")
    .nullable(),
  vehicleImageFrontSide: yup
    .object()
    .shape({
      url: yup.string().required().nullable(),
      id: yup.string().required().nullable(),
      name: yup.string().required().nullable()
    })
    .required("Vehicle Image Front Side is required")
    .nullable(),
  vehicleImageBackSide: yup
    .object()
    .shape({
      url: yup.string().required().nullable(),
      id: yup.string().required().nullable(),
      name: yup.string().required().nullable()
    })
    .required("Vehicle Image Back Side is required")
    .nullable(),
  vehicleRegistration: yup
    .object()
    .shape({
      url: yup.string().required().nullable(),
      id: yup.string().required().nullable(),
      name: yup.string().required().nullable()
    })
    .required("Vehicle Registration is required")
    .nullable(),
  vehicleInsurance: yup
    .object()
    .shape({
      url: yup.string().required(),
      id: yup.string().required(),
      name: yup.string().required()
    })
    .required("Vehicle Insurance is required")
    .nullable()
});
