import { Col, Row } from "antd";
import { DEFAULT_CENTER, CCP_DEFAULT_CENTER } from "configs/constants";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useState } from "react";
import ReactMapboxGl from "react-mapbox-gl";
import PickupLayer from "./layers/PickupLayer";
import "./MapBox.scss";
import { setMapboxRTLTextPlugin } from "helper/util";

mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
setMapboxRTLTextPlugin(mapboxgl);

const Map = ReactMapboxGl({
  minZoom: 8,
  accessToken: window.REACT_APP_MAPBOX_KEY
});

const MapBoxJobDetail = ({ stops = [], height, width = "100%" }) => {
  const [routes, setRoutes] = useState([]);
  const [bounds, setBounds] = useState(undefined);
  const [center, setCenter] = useState(
    window.REACT_APP_DD_ENV === "ccpexpress" ? CCP_DEFAULT_CENTER : DEFAULT_CENTER
  );
  const [zoomLevel, setZoomLevel] = useState(12);

  const handleLoaded = () => {
    setZoomLevel(12.001);
  };

  const getBounds = (listCoordinate) => {
    let x0, x1, y0, y1;
    listCoordinate.forEach((coordinate) => {
      if (!x0) {
        x0 = x1 = coordinate.lat;
        y0 = y1 = coordinate.lng;
      } else {
        if (coordinate.lat > x1) x1 = Number(coordinate.lat);
        if (coordinate.lat < x0) x0 = Number(coordinate.lat);
        if (coordinate.lng > y1) y1 = Number(coordinate.lng);
        if (coordinate.lng < y0) y0 = Number(coordinate.lng);
      }
    });
    const latCenter = (x1 + x0) / 2;
    const lngCenter = (y1 + y0) / 2;
    return {
      bounds: [
        [y1, x1],
        [y0, x0]
      ],
      center: {
        lat: latCenter,
        lng: lngCenter
      }
    };
  };

  useEffect(() => {
    if (stops?.length > 0) {
      const data = getBounds(stops);
      setBounds(data.bounds);
      setCenter(data.center);
    }
  }, [stops]);

  if (isNaN(height)) {
    return <div />;
  }

  return (
    <div className="map-box">
      <Map
        center={center}
        fitBounds={bounds}
        style="mapbox://styles/mapbox/streets-v11"
        movingMethod="easeTo"
        zoom={[zoomLevel]}
        containerStyle={{
          height,
          width
        }}
        onStyleLoad={() => handleLoaded()}
      >
        <PickupLayer stops={stops} />
      </Map>
      <Row className="annotation-table bg-white border-radius-1">
        <Col className="gutter-row" span={12}>
          <div className="d-flex flex-items-center gap-2">
            <div className="icon delivery-pickup"></div>
            <div className="text">Customer Pickup Location</div>
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div className="d-flex flex-items-center gap-2">
            <div className="icon delivery-dropoff"></div>
            <div className="text">Customer Delivery Location</div>
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div className="d-flex flex-items-center gap-2">
            <div className="icon arrivedAt-pickup"></div>
            <div className="text">Pickup Arrived Location</div>
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div className="d-flex flex-items-center gap-2">
            <div className="icon arrivedAt-dropoff"></div>
            <div className="text">Delivery Arrived Location</div>
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div className="d-flex flex-items-center gap-2">
            <div className="icon completedAt-pickup"></div>
            <div className="text">Pickup Completed Location</div>
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div className="d-flex flex-items-center gap-2">
            <div className="icon completedAt-dropoff"></div>
            <div className="text">Delivery Completed Location</div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default MapBoxJobDetail;
