import { useEffect, useRef } from "react";
import moment from "moment";
import { random, flatten as _flatten, isEmpty } from "lodash";
import { Country, State } from "country-state-city";
import {
  DATE_FORMAT_API_BACKEND,
  DATETIME_FORMAT_FRONTEND,
  DATE_FORMAT_FRONTEND,
  VEHICLE_RATE_TYPES,
  DAY_DATE_FORMAT
} from "configs/constants";
import request from "./request";

export const generateId = (suffix) => {
  return `${new Date().getTime()}-${random(100000)}-${suffix}`;
};
export const fullName = (item) => {
  if (!item?.firstName && !item?.lastName) {
    return "N/A";
  }
  return `${item?.firstName?.trim()} ${item?.lastName?.trim()}`;
};
export const pluck = (key, array) =>
  array.reduce((values, current) => {
    values.push(current[key]);

    return values;
  }, []);

export const searchFor = (toSearch, objects) => {
  var results = [];
  toSearch = trimString(toSearch).toLowerCase();
  for (var i = 0; i < objects.length; i++) {
    for (var key in objects[i]) {
      if (String(objects[i][key]).toLowerCase().indexOf(toSearch) != -1) {
        if (!itemExists(results, objects[i])) results.push(objects[i]);
      }
    }
  }
  return results;
};

const trimString = (s) => {
  var l = 0,
    r = s.length - 1;
  while (l < s.length && s[l] == " ") l++;
  while (r > l && s[r] == " ") r -= 1;
  return s.substring(l, r + 1);
};

const itemExists = (haystack, needle) => {
  for (var i = 0; i < haystack.length; i++)
    if (compareObjects(haystack[i], needle)) return true;
  return false;
};

const compareObjects = (o1, o2) => {
  var k = "";
  for (k in o1) if (o1[k] != o2[k]) return false;
  for (k in o2) if (o1[k] != o2[k]) return false;
  return true;
};

export const makeId = (length) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const convertHexToRGBA = (hex, opacity) => {
  const tempHex = hex.replace("#", "");
  const r = parseInt(tempHex.substring(0, 2), 16);
  const g = parseInt(tempHex.substring(2, 4), 16);
  const b = parseInt(tempHex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

export const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export const replaceElementInArray = (arr, ele, key = "id") => {
  const tmpObject = convertArrayToObject([...arr], key);
  const newObject = { ...tmpObject, [ele[key]]: ele };

  return Object.values(newObject);
};

export const assignBy = (key) => {
  return (data, item) => {
    data[item[key]] = item;
    return data;
  };
};

export const convertArrayToObject = (data, key) => {
  return data.reduce(assignBy(key), {});
};

export const convertObjectToArray = (data, keyName = "keyName") => {
  const tmpData = { ...data };
  const keys = Object.entries(tmpData);
  const arr = [];
  keys.forEach(([key, value]) => {
    arr.push({
      id: key,
      [keyName]: key,
      ...value
    });
  });
  return arr;
};

export const showDate = (date) => {
  if (!date) {
    return "N/A";
  }
  return moment(date).format(DATETIME_FORMAT_FRONTEND);
};

export const showDay = (date) => {
  if (!date) {
    return "N/A";
  }
  return moment(date).format(DAY_DATE_FORMAT);
};

export const showInfo = (info) => {
  if (!info) {
    return "N/A";
  }
  return info;
};

export const handleTitleTab = (title, position) => {
  return `${title ? title : ""} ${position ? position : ""}`;
};

export const shortId = (id) => id?.substr(0, 8)?.toUpperCase();

export const formatCurrency = (money) => {
  if (!money) return null;
  else return window.CURRENCY_SYMBOL + money.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const allowPermission = (role, permission = []) => {
  return permission.includes(role);
};

export const appendDefaultOption = (valueSelect, labelSelect, options) => {
  return [{ [labelSelect]: "Please Select", [valueSelect]: "" }, ...options];
};

export const loadAllCountries = () => {
  return appendDefaultOption("isoCode", "name", Country.getAllCountries());
};

export const loadAllStates = () => {
  return appendDefaultOption("isoCode", "name", State.getAllStates());
};

export function formatVehicleCategories(vehicleCategoriesRates) {
  const vehicleRates = vehicleCategoriesRates.map(({ id, clientRates, driverRates }) => {
    return [
      { vehicleCategoryId: id, type: VEHICLE_RATE_TYPES.CLIENT_RATE, ...clientRates },
      { vehicleCategoryId: id, type: VEHICLE_RATE_TYPES.DRIVER_RATE, ...driverRates }
    ];
  });
  return _flatten(vehicleRates);
}

export function formatDateToBackend(value) {
  let formattedValue;

  if (!value) {
    formattedValue = null;
  } else if (moment(value, DATE_FORMAT_FRONTEND).format(DATE_FORMAT_FRONTEND) === value) {
    formattedValue = moment(value, DATE_FORMAT_FRONTEND).format(DATE_FORMAT_API_BACKEND);
  } else if (
    moment(value, DATE_FORMAT_API_BACKEND).format(DATE_FORMAT_API_BACKEND) === value
  ) {
    formattedValue = value;
  } else {
    formattedValue = moment(value).format(DATE_FORMAT_API_BACKEND);
  }

  return formattedValue;
}

export const convertEmergencyStatus = (route) =>
  route?.isEmergency ? "emergency" : route?.status;

export const getTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const buildFirstLevelFilterPoolExport = (filter) => {
  if (!filter) return [];
  const FIRST_LEVEL_FILTER = [
    "id",
    "client_id",
    "startPickupDate",
    "endPickupDate",
    "status",
    "orderId"
  ];

  const FILTER_VALUES = {
    id: "$cont",
    orderId: "$cont",
    client_id: "$eq",
    startPickupDate: "$eq",
    endPickupDate: "$eq",
    status: "$eq"
  };
  return Object.keys(filter)
    .filter((field) => FIRST_LEVEL_FILTER.includes(field) && !isEmpty(filter[field]))
    .map((filteredField) => {
      let value = filter[filteredField];
      if (filteredField === "startPickupDate") {
        value = moment(value, DATE_FORMAT_FRONTEND).format(DATE_FORMAT_FRONTEND);
      }
      if (filteredField === "endPickupDate") {
        value = moment(value, DATE_FORMAT_FRONTEND).format(DATE_FORMAT_FRONTEND);
      }
      return `${filteredField}||${FILTER_VALUES[filteredField]}||${value}`;
    });
};

export const validateUUID = (id) => {
  if (id && id?.length < 36) {
    return false;
  }
  return true;
};

export const getUTMParameters = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const utmParams = {
    source: urlParams.get("utm_source"),
    medium: urlParams.get("utm_medium"),
    campaign: urlParams.get("utm_campaign"),
    term: urlParams.get("utm_term"),
    content: urlParams.get("utm_content")
  };

  return utmParams;
};

export const hubspotTrackingIdentify = (client) => {
  if (!window?._hsq) {
    return;
  }

  // SW-930; added utm_source for hubspot contact referrals
  const utmParameters = getUTMParameters();
  let _hsq = (window._hsq = window._hsq || []);

  const identifyPayload = {
    email: client?.email,
    phone: client?.phone,
    firstname: client?.firstName,
    lastname: client?.lastname,
    company: client?.clientName,
    country: client?.country
    //originalSourceType: "UTM",
    //originalSourceDetail: utmParameters.source
  };

  // Add all non-empty UTM parameters to the payload
  Object.entries(utmParameters).forEach(([key, value]) => {
    if (value) {
      identifyPayload[`utm_${key}`] = value;
    }
  });

  _hsq.push(["identify", identifyPayload]);
};

export const formatToUSD = (unit) => {
  const usdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });

  return usdFormatter.format(unit);
};

export const createSubscriptionSession = async (priceId) => {
  try {
    const sessionRes = await request.post(
      `${window.REACT_APP_API_ENDPOINT}/subscriptions/create-checkout-session`,
      {
        priceId
      }
    );

    if (!sessionRes) return;

    window.location.replace(sessionRes.url);
  } catch (error) {
    console.log(error);
  }
};

export const poolPackageSizes = ["Postal", "Micro", "Small", "Medium"];

export const removeSpecialChars = (str) => {
  if (!str) return "";

  return str.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]/]/gi, " ");
};

export const DRIVER_STATUS_OPTIONS = [
  {
    value: "active",
    name: "Active"
  },
  {
    value: "pending",
    name: "Pending"
  },
  {
    value: "in_review",
    name: "In Review"
  },
  {
    value: "rejected",
    name: "Rejected"
  },
  {
    value: "deactivated",
    name: "Deactivated"
  }
];

export const phoneTypeOptions = [
  { value: "android", name: "Android" },
  { value: "iphone", name: "iPhone" }
];
export const serviceProviderOptions = [
  { value: "claro", name: "Claro" },
  { value: "tMobile", name: "T Mobile" },
  { value: "liberty", name: "Liberty" },
  { value: "boostMobile", name: "Boost Mobile" },
  { value: "other", name: "Other" }
];

export const removeAmazonConnectChatElement = () => {
  const allChats = document.querySelectorAll("#amazon-connect-chat-widget");
  const allChatsArr = Array.from(allChats);
  allChatsArr.map((chat) => chat.remove());
};

export const setMapboxRTLTextPlugin = (mapboxgl) => {
  if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {
    mapboxgl.setRTLTextPlugin(
      "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.3.0/mapbox-gl-rtl-text.js",
      null,
      true
    );
  }
};
