import { Col, Form, Input, Radio, Row } from "antd";
import { convertArrayToObject } from "helper/util";
import { sortBy } from "lodash";
import sumBy from "lodash/sumBy";
import React, { useEffect, useState } from "react";
import "./PackForm.scss";

// const typesDefault = {
//   micro: {
//     maxLength: 32,
//     maxWeight: 70,
//     maxVolume: 8
//   },
//   small: {
//     maxLength: 40,
//     maxWeight: 74,
//     maxVolume: 10
//   },
//   medium: {
//     maxLength: 84,
//     maxWeight: 102,
//     maxVolume: 20
//   },
//   large: {
//     maxLength: 100,
//     maxWeight: 114,
//     maxVolume: 30
//   }
// };

const initValue = {
  packageTypeId: null,
  quantity: null,
  name: null,
  maxHeight: null,
  maxLength: null,
  maxWeight: null,
  maxVolume: null,
  barcode: null
};

const PackForm = ({ packageTypeList, pack, formPack, onOk }) => {
  const objPackageTypeList = convertArrayToObject(packageTypeList, "id");
  const [packageType, setPackageType] = useState(null);
  const [quantityCurrent, setQuantityCurrent] = useState(0);

  useEffect(() => {
    if (pack) {
      setPackageType(objPackageTypeList[pack.packageTypeId]);
      formPack.setFieldsValue({ ...pack, barcode: pack.barcode });
      if (pack.dropoffs?.length) {
        setQuantityCurrent(sumBy(pack.dropoffs, "quantity"));
      } else {
        setQuantityCurrent(0);
      }
    } else {
      setPackageType(null);
      formPack.setFieldsValue(initValue);
      setQuantityCurrent(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pack, formPack]);

  const onChangeType = (e) => {
    setPackageType(objPackageTypeList[e.target.value]);
  };

  const onFinish = (values) => {
    const fields = {
      id: pack?.id,
      name: packageType?.name,
      action: null,
      quantity: values.quantity,
      barcode: values.barcode
    };
    fields.packageTypeId = packageType?.id;
    if (values?.name?.toLowerCase() === "custom") {
      fields.maxLength = values.maxLength;
      fields.maxWeight = values.maxWeight;
      fields.maxVolume = values.maxVolume;
      fields.maxHeight = values.maxHeight;
    } else {
      fields.maxLength = packageType?.maxLength;
      fields.maxWeight = packageType?.maxWeight;
      fields.maxVolume = packageType?.maxVolume;
    }

    onOk && onOk(fields);
  };

  return (
    <div className="PackForm">
      <Form
        scrollToFirstError={true}
        layout="vertical"
        form={formPack}
        hideRequiredMark
        colon={false}
        onFinish={onFinish}
        initialValues={pack}
      >
        <Form.Item
          name="packageTypeId"
          rules={[{ required: true, message: "Required" }]}
          className="type"
        >
          <Radio.Group onChange={onChangeType} value={packageType?.id}>
            {sortBy(packageTypeList, "maxWeight").map(
              (item) =>
                item.name !== "Custom" && (
                  <Radio key={item?.id} value={item?.id} checked={item?.id === pack?.id}>
                    {item?.name}
                  </Radio>
                )
            )}
          </Radio.Group>
        </Form.Item>
        <div className="normal-type">
          <div className="box">
            <img src="/box.png" alt="" />
          </div>
          {packageType !== "custom" && (
            <div className="types">
              <p>
                Max Weight:{" "}
                <b>
                  {packageType?.maxWeight || "-"}{" "}
                  {window.REACT_APP_DD_ENV === "ccpexpress" ? "kg" : "Lbs"}
                </b>
              </p>
              <p>
                Max Length:{" "}
                <b>
                  {packageType?.maxLength || "-"}{" "}
                  {window.REACT_APP_DD_ENV === "ccpexpress" ? "centimeters" : "Inches"}
                </b>
              </p>
              <p>
                Max Volume:{" "}
                <b>
                  {packageType?.maxVolume || "-"}{" "}
                  {window.REACT_APP_DD_ENV === "ccpexpress" ? <>&#13221;</> : "Cuft"}
                </b>
              </p>
            </div>
          )}
        </div>

        <div className="pack-info">
          <Row>
            <Col span="8">
              <Form.Item
                label="Quantity"
                name="quantity"
                rules={[
                  { required: true, message: "Required" },
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject();
                      }
                      if (Number(value) < 1) {
                        return Promise.reject("Invalid data");
                      }
                      if (Number(value) < quantityCurrent) {
                        return Promise.reject("Invalid data");
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <Input type="number" placeholder="Quantity" />
              </Form.Item>
            </Col>
            <Col span="16">
              <Form.Item
                label="Barcode number (optional)"
                name="barcode"
                rules={[{ required: false }]}
              >
                <Input placeholder="Barcode number" />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default PackForm;
