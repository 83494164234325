import React, { useCallback, useEffect, useState } from "react";
import request from "helper/request";
import PromoCodeList from "../components/PromoCodeList";
import { Button, DatePicker, Form, Input, Modal, Select, message } from "antd";
import { PercentageOutlined, PlusOutlined } from "@ant-design/icons";
import PromoCodeFilter from "../components/PromoCodeFilter";

const PromoCode = () => {
  const [promoCodes, setPromoCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [promoType, setPromoType] = useState("");
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    getAllCodes();
  }, []);

  const getAllCodes = useCallback(async () => {
    try {
      setLoading(true);
      const codes = await request.get("/admin/promo-code");

      if (!codes.length) {
        return;
      }

      setPromoCodes(addKeysToObj(codes));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const addKeysToObj = (objArray) => {
    return objArray.map((item, index) => ({ ...item, key: index + 1 }));
  };

  const addNewPromoCode = async (submittedData) => {
    try {
      delete submittedData.promoType;
      const formData = {
        ...submittedData,
        expiryDate: submittedData.expiryDate.format(),
        isPercent: promoType === "percent" ? true : false,
        active: true
      };
      await request.post("/admin/promo-code", formData);
      await getAllCodes();
      setShowModal(!showModal);
      form.resetFields();
      messageApi.open({
        type: "success",
        content: "Added promo code successfully!",
        duration: 5
      });
    } catch (error) {
      setShowModal(!showModal);
      form.resetFields();
      setPromoType("");
      messageApi.open({
        type: "error",
        content: error?.response.message,
        duration: 5
      });
    }
  };

  const deletePromoCode = async (id) => {
    try {
      await request.delete(`/admin/promo-code/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {contextHolder}
      {false && (
        <PromoCodeFilter
          submitForm={() => {
            return "";
          }}
        />
      )}
      <div style={{ display: "flex", margin: "1em 0", justifyContent: "flex-end" }}>
        <Button type="primary" onClick={() => setShowModal(!showModal)}>
          Add Promo Code
          <PlusOutlined />
        </Button>
      </div>
      <PromoCodeList
        data={promoCodes}
        getAllCodes={getAllCodes}
        loading={loading}
        deletePromoCode={deletePromoCode}
        setLoading={setLoading}
      />
      <Modal open={showModal} footer={false} onCancel={() => setShowModal(!showModal)}>
        <h3>Add Promo Code</h3>
        <Form form={form} layout="vertical" onFinish={addNewPromoCode}>
          <Form.Item
            label="Promo Code"
            name="discountName"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input placeholder="Promo Code" />
          </Form.Item>
          <Form.Item
            label="Expiry Date"
            name="expiryDate"
            rules={[{ required: true, message: "Required" }]}
          >
            <DatePicker onChange={(e) => console.log(e.format())} />
          </Form.Item>
          <Form.Item label="Type of Discount" name="promoType">
            <Select defaultValue={""} onChange={(value) => setPromoType(value)}>
              <Select.Option value="">Choose a type</Select.Option>
              <Select.Option value="amount">Amount</Select.Option>
              <Select.Option value="percent">Percent</Select.Option>
            </Select>
          </Form.Item>
          {promoType && (
            <>
              {promoType === "amount" ? (
                <Form.Item
                  label="Amount"
                  name="amountOff"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input placeholder="Amount" prefix="$" maxLength={3} />
                </Form.Item>
              ) : (
                <Form.Item
                  label="Percent"
                  name="percentOff"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input
                    placeholder="Percent"
                    prefix={<PercentageOutlined />}
                    maxLength={2}
                  />
                </Form.Item>
              )}
            </>
          )}
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default PromoCode;
