/* eslint-disable react/display-name */
import { Popconfirm, Space } from "antd";
import { canDeletePermission } from "configs/constants";
import { canEditPermission } from "configs/constants";
import { allowPermission } from "helper/util";
import { selectRole } from "modules/auth/store/authSlice";
import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import TableBase from "../../../../../components/table/TableBase";
import { doDeletePackageType } from "../../store/PackageTypeThunks";

const PackageTypeTable = ({ dataSource, pagination }) => {
  const role = useSelector(selectRole);
  const canEdit = allowPermission(role, canEditPermission);
  const canDelete = allowPermission(role, canDeletePermission);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleDelete = (packageType) => {
    dispatch(doDeletePackageType(packageType));
  };

  const handleEdit = (record) => {
    history.push(`/admin/settings/package-types/${record.id}`);
  };
  const columns = [
    {
      title: "Package Type Name",
      dataIndex: "name",
      width: 300,
      render: (value) => {
        return <p style={{ margin: 0, color: "#1f3998" }}>{value}</p>;
      }
    },
    {
      title: `Max Weight (${window.REACT_APP_DD_ENV === "ccpexpress" ? "kg" : "lbs"})`,
      dataIndex: "maxWeight"
    },
    {
      title: `Max Length (${window.REACT_APP_DD_ENV === "ccpexpress" ? "cm" : "in"})`,
      dataIndex: "maxLength"
    },
    {
      title: `Max Volume (${window.REACT_APP_DD_ENV === "ccpexpress" ? "㎥" : "cuft"})`,
      dataIndex: "maxVolume"
    }
  ];
  if (canEdit || canDelete) {
    columns.push({
      title: "",
      key: "action",
      width: 100,
      fixed: "right",
      // eslint-disable-next-line react/display-name
      render: (value, record) => {
        return (
          <Space size="middle">
            {canEdit && (
              <img
                src="/icons/edit.svg"
                className={`table-status ${value}`}
                onClick={() => handleEdit(record)}
              />
            )}
            {canDelete && (
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={() => handleDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <img src="/icons/delete.svg" className={`table-status ${value}`} />
              </Popconfirm>
            )}
          </Space>
        );
      }
    });
  }
  return (
    <TableBase columns={columns} data={dataSource} pagination={pagination} rowKey="id" />
  );
};
export default PackageTypeTable;
