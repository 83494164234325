import React, { useCallback, useEffect, useState, useContext, useMemo } from "react";
import { Button, Form, Menu } from "antd";
import request from "helper/request";
import { formatToUSD } from "helper/util";
import { useHistory } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import "./index.css";
import { FeatureFlagsContext } from "layouts/private/FeatureFlagsContext";

function SubscriptionOverlay({ handleSubmit, hasUsedTrial }) {
  const featureFlagsContext = useContext(FeatureFlagsContext);
  const isPoolAvailable = useMemo(() => featureFlagsContext?.isPoolAvailable);
  const history = useHistory();
  const [subscriptionProducts, setSubscriptionProducts] = useState({});
  const [closeSubscriptionOverlay, setCloseSubscriptionOverlay] = useState(false);
  const onSubmit = useCallback((id) => {
    handleSubmit(id);
  });

  const getSubscriptionProducts = useCallback(async () => {
    const products = await request.get(
      `${window.REACT_APP_API_ENDPOINT}/subscriptions/subscription-tier`
    );

    if (!products) return;

    setSubscriptionProducts(products);
  }, []);

  useEffect(() => {
    getSubscriptionProducts();
  }, []);

  return (
    <>
      {subscriptionProducts?.features && (
        <div style={containerStyle} hidden={closeSubscriptionOverlay}>
          <div className="close-btn-container">
            <div className="close-btn-wrapper">
              <CloseOutlined
                className="close-icon"
                onClick={() => setCloseSubscriptionOverlay(!closeSubscriptionOverlay)}
              />
            </div>
          </div>
          <div style={wrapperStyle}>
            {isPoolAvailable && (
              <div className="free-card" style={subItemStyle}>
                <div style={{ textAlign: "center" }}>
                  <h2
                    className="mb-3 p-2  text-center bold"
                    style={{
                      ...headingLine,
                      fontSize: "2em",
                      textTransform: "uppercase"
                    }}
                  >
                    FLEXIO POOL
                  </h2>
                  <div
                    style={{
                      // height: "266px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <div
                      style={{
                        maxWidth: 250,
                        textAlign: "center",
                        margin: "3em 0"
                      }}
                    >
                      <p className="bold" style={{ fontSize: "16px" }}>
                        Need reliable, cost-effective next-day delivery for your package?
                      </p>
                      <p className="mb-0">
                        Flexio Pool offers delivery at a fixed price, ensuring your orders
                        arrive quickly and affordably.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="text-center mt-3 mb-2">
                  <p>
                    <small>No monthly fee required</small>
                  </p>
                  <Button
                    className="mt-5"
                    style={{
                      ...subButtonStyle,
                      background: "#3c56a6",
                      color: "#fff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "20px"
                    }}
                    htmlType="submit"
                    onClick={() => {
                      history.push("/pool");
                    }}
                  >
                    {"Go to FLEXIO POOL"}
                  </Button>
                </div>
              </div>
            )}

            <div className="pro-card" style={subItemStyle}>
              <div>
                <h2
                  className="mb-3 p-2  text-center bold"
                  style={{
                    ...headingLine,
                    fontSize: "2em",
                    textTransform: "uppercase"
                  }}
                >
                  {subscriptionProducts?.tierType}
                </h2>

                <div
                  className="mb-3"
                  style={{
                    maxWidth: 2500,
                    marginLeft: "auto",
                    marginRight: "auto",
                    textAlign: "center"
                  }}
                >
                  <h2 className="m-0 mb-0">
                    <strong style={{ fontSize: "1.5em" }}>
                      ${subscriptionProducts?.amount}
                    </strong>
                    <small style={{ fontSize: "16px" }}> per driver / mo</small>
                  </h2>
                </div>

                <div
                  style={{
                    width: "170px",
                    marginTop: "-20px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "20px",
                    textAlign: "left"
                  }}
                >
                  <p className="m-0 p-0 ml-1" style={{ color: "#777" }}>
                    2 Drivers minimum
                  </p>
                  <p className="m-0 p-0 ml-1" style={{ color: "#777" }}>
                    {formatToUSD(subscriptionProducts?.amount * 2)}
                    <small> / mo minimum</small>
                  </p>
                </div>

                <ul className="mb-3" style={{ marginLeft: "40px" }}>
                  {subscriptionProducts?.features?.map((features, featureIdx) => (
                    <li
                      key={featureIdx}
                      className="mb-2 bold"
                      style={{ paddingLeft: "2px", fontSize: "13px" }}
                    >
                      {features}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="text-center mb-2">
                <a
                  href="https://www.goflexio.com/saas"
                  className="d-block text-center "
                  style={{ color: "#3ad", textDecoration: "underline" }}
                >
                  And much more
                </a>
                <Button
                  className="mt-4"
                  style={{
                    ...subButtonStyle,
                    color: "black",
                    border: "solid",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "20px"
                  }}
                  htmlType="submit"
                  onClick={() => onSubmit(subscriptionProducts.id)}
                >
                  {hasUsedTrial ? "Subscribe" : "Start 30 Days Free Trial"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const containerStyle = {
  maxWidth: "100%",
  minHeigth: "100%",
  position: "absolute",
  width: "100%",

  zIndex: 99,
  backgroundColor: "#bbbbbb80",
  backdropFilter: "blur(6px)",
  top: 0,
  left: 0
};

const wrapperStyle = {
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "center",
  height: "100vh",
  padding: "40px",
  position: "relative"
};

const subItemStyle = {
  width: "340px",
  padding: "2em 2em",
  backgroundColor: "#fff",
  borderRadius: "10px",
  border: "solid 1px #bbb",
  margin: "0 2em",
  height: "540px"
};

const subButtonStyle = {
  marginLeft: "50%",
  transform: "translateX(-50%)",
  borderRadius: 30,
  border: 0
};

const headingLine = {
  borderBottom: "2px solid #6078ea"
};

export default SubscriptionOverlay;
