import React from "react";
import { Card, Select } from "antd";
import { Chart } from "react-chartjs-2";
import "chart.js/auto";

const RevenueChart = () => {
  return (
    <div className="chart-revenue">
      <Card>
        <div
          className="d-flex m-3"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <div className="chart-header">
            <h1>Overview</h1>
            <p>Monthly Earning</p>
          </div>
          <div>
            <Select
              defaultValue="quarterly"
              // onChange={handleChange}
              options={[
                { value: "weekly", label: "Weekly" },
                { value: "monthly", label: "Monthly" },
                { value: "yearly", label: "Yearly" },
                { value: "quarterly", label: "Quarterly" }
              ]}
            />
          </div>
        </div>
        <Chart
          type="bar"
          redraw
          data={{
            labels: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Agu",
              "Sep",
              "Oct",
              "Nov",
              "Dec"
            ],
            datasets: [
              {
                label: "Revenue",
                data: [
                  44050, 48000, 37405, 44394, 44200, 44050, 48000, 37405, 44394, 44200,
                  44050, 48000
                ],
                backgroundColor: [
                  "#45c9ed60",
                  "#45c9ed60",
                  "#45c9ed60",
                  "#45c9ed60",
                  "#45c9ed60",
                  "#45c9ed60",
                  "#45c9ed60",
                  "#45c9ed60",
                  "#45c9ed60",
                  "#45c9ed60",
                  "#45c9ed60",
                  "#45c9ed60"
                ],
                barPercentage: 0.8,
                borderColor: "#00000000",
                fill: false,
                hoverBackgroundColor: "#45c9ed",
                borderRadius: 10
              }
            ]
          }}
          options={{
            responsive: true,
            plugins: {
              tooltip: {
                enabled: true,
                position: "nearest",
                backgroundColor: "#3c41c2",
                callbacks: {
                  title: (items) =>
                    `${(
                      ((items[0].parsed.y - (Math.random() + 100) * 100 - 500) /
                        items[0].parsed.y) *
                      100
                    ).toFixed(2)}%`,
                  label: () => ""
                }
              }
            },
            scales: {
              x: {
                border: {
                  color: "#00000000"
                },
                grid: {
                  display: false
                }
              },
              y: {
                display: false,
                grid: {
                  display: false
                }
              }
            }
          }}
        />
      </Card>
    </div>
  );
};

export default RevenueChart;
