import { Tag, Progress, Button, Tooltip } from "antd";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import React, { useCallback, useContext, useMemo } from "react";
import AdminBreadCrumb from "components/AdminBreadCrumb";
import "./OverviewPage.scss";
import StatisticCard from "components/StatisticCard";
import user from "../../../../components/icons/user.svg";
import advancedInfo from "../../../../components/icons/advancedInfo.svg";
import payment from "../../../../components/icons/payment.svg";
import car from "../../../../components/icons/car.svg";
import { DriverProfileContext } from "layouts/privateDriver/DriverProfileContext";
//import "./index.scss";
import { Link } from "react-router-dom";
import request from "helper/request";
import { useDispatch } from "react-redux";
import { setSuccessMessage } from "app/global/slice";
import { removeSpecialChars } from "helper/util";

const breadCrumbs = [{ text: "DRIVER OVERVIEW" }];

const DriverOverviewPage = () => {
  const driverProfileCtx = useContext(DriverProfileContext);
  const fetchDriverProfile = driverProfileCtx.fetchProfile;
  const driverProfile = useMemo(() => driverProfileCtx.profile, [driverProfileCtx]);
  const emptyFields = driverProfile?.emptyFields;
  const dispatch = useDispatch();

  const totalBasicInformationFields = 9;
  const totalAdvancedInformationFields = 12;
  const totalPaymentInformationFields = 6;
  const totalVehicle = 1;

  const calculatePercentage = (missingFields, totalFields) => {
    if (missingFields === totalFields) return 0;
    return 100 - Math.round((missingFields / totalFields) * 100);
  };

  const submitForReview = useCallback(async () => {
    try {
      await request.post("/drivers/submit-review", {});
      dispatch(setSuccessMessage("Your account has been submitted for review!"));
      fetchDriverProfile();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const showSubmitForReview = () => {
    // we have some optional fields, so let's check to ensure.
    // we do require ALL basic info (bug with yup on emergency phone)
    // Advanced can be length 0 or ok to have taxName / Address optionals as blank
    // Payment info same with optional email
    // To me it appears like Vehicles is optional

    const isAdvancedInformationGood =
      emptyFields?.advancedInformation?.includes("taxName") ||
      emptyFields?.advancedInformation?.includes("taxAddress") ||
      (emptyFields?.advancedInformation?.includes("taxName") &&
        emptyFields?.advancedInformation?.includes("taxAddress")) ||
      emptyFields?.advancedInformation.length === 0;

    const isPaymentInformationGood =
      emptyFields?.paymentInformation?.length <= 1 ||
      emptyFields?.paymentInformation?.includes("emailBank");

    return (
      driverProfile?.status == "pending" &&
      emptyFields?.basicInformation?.length === 0 &&
      isAdvancedInformationGood &&
      isPaymentInformationGood
    );
  };

  const missingInformationTitle = (
    <>
      <h1 style={{ color: "#7d7d7d" }}>
        Missing Information <ExclamationCircleTwoTone twoToneColor="#ffcb0f" />
      </h1>
    </>
  );

  return (
    <div>
      <AdminBreadCrumb breadCrumbs={breadCrumbs} />
      <div>
        <div className="status">
          <div>Status: </div>
          <Tag
            color={
              driverProfile?.status === "active"
                ? "green"
                : driverProfile?.status === "in_review"
                ? "orange"
                : "yellow"
            }
          >
            {removeSpecialChars(driverProfile?.status).toUpperCase()}
          </Tag>
        </div>
        <div className="overview">
          <div
            className="cards-container" /* style={{ display: "flex", flexDirection: "column" }} */
          >
            <div className="card-container">
              <Link to="basic-information" className="card">
                <StatisticCard
                  title={
                    emptyFields?.basicInformation?.length > 0
                      ? missingInformationTitle
                      : ""
                  }
                  className="m-1 stats-card"
                  icon={
                    <span
                      style={{
                        background: `url(${user}) center no-repeat`,
                        backgroundSize: "contain",
                        width: "8em",
                        height: "8em",
                        display: "block",
                        opacity: 0.1,
                        marginTop: "70%",
                        marginRight: "10px"
                      }}
                    ></span>
                  }
                  data={"Basic Information"}
                  others={
                    <Progress
                      percent={calculatePercentage(
                        emptyFields?.basicInformation.length,
                        totalBasicInformationFields
                      )}
                      status="active"
                      /* steps={5} */
                      style={{ width: "80%" }}
                      trailColor="#d9d9d9"
                      strokeColor="#2491bc"
                    ></Progress>
                  }
                />
              </Link>
            </div>
            <div className="card-container">
              <Link to="advanced-information" className="card">
                <StatisticCard
                  title={
                    emptyFields?.advancedInformation?.length > 0
                      ? missingInformationTitle
                      : ""
                  }
                  className="m-1 stats-card"
                  icon={
                    <span
                      style={{
                        background: `url(${advancedInfo}) center no-repeat`,
                        backgroundSize: "contain",
                        width: "8em",
                        height: "8em",
                        display: "flex",
                        opacity: 0.1,
                        marginTop: "70%",
                        marginRight: "10px"
                      }}
                    ></span>
                  }
                  data={"Advanced Information"}
                  others={
                    <Progress
                      percent={calculatePercentage(
                        emptyFields?.advancedInformation.length,
                        totalAdvancedInformationFields
                      )}
                      status="active"
                      /* steps={5} */
                      style={{ width: "80%" }}
                      trailColor="#d9d9d9"
                      strokeColor="#2491bc"
                    ></Progress>
                  }
                />
              </Link>
            </div>
            <div className="card-container">
              <Link to="payment-information" className="card">
                <StatisticCard
                  title={
                    emptyFields?.paymentInformation?.length > 0
                      ? missingInformationTitle
                      : ""
                  }
                  className="m-1 stats-card"
                  icon={
                    <span
                      style={{
                        background: `url(${payment}) center no-repeat`,
                        backgroundSize: "contain",
                        width: "8em",
                        height: "8em",
                        display: "block",
                        opacity: 0.1,
                        marginTop: "70%",
                        marginRight: "10px"
                      }}
                    ></span>
                  }
                  data={"Payment Information"}
                  others={
                    <Progress
                      percent={calculatePercentage(
                        emptyFields?.paymentInformation.length,
                        totalPaymentInformationFields
                      )}
                      status="active"
                      style={{ width: "80%" }}
                      trailColor="#d9d9d9"
                      strokeColor="#2491bc"
                    ></Progress>
                  }
                />
              </Link>
            </div>
            <div className="card-container">
              <Link to={`vehicles/${driverProfile?.id}`} className="card">
                <StatisticCard
                  title={
                    emptyFields?.vehicleInformation?.length > 0
                      ? missingInformationTitle
                      : ""
                  }
                  className="m-1 stats-card"
                  icon={
                    <span
                      style={{
                        background: `url(${car}) center no-repeat`,
                        backgroundSize: "contain",
                        width: "8em",
                        height: "8em",
                        display: "block",
                        opacity: 0.1,
                        marginTop: "70%",
                        marginRight: "10px"
                      }}
                    ></span>
                  }
                  data={"Vehicles"}
                  others={
                    <Progress
                      percent={calculatePercentage(
                        emptyFields?.vehicleInformation.length,
                        totalVehicle
                      )}
                      status="active"
                      style={{ width: "80%" }}
                      trailColor="#d9d9d9"
                      strokeColor="#2491bc"
                    ></Progress>
                  }
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
        <Tooltip
          title={
            !showSubmitForReview()
              ? "Once all information has been filled you will be able to submit for review."
              : ""
          }
        >
          <Button
            onClick={submitForReview}
            disabled={!showSubmitForReview()}
            type="primary"
          >
            Submit for review
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default DriverOverviewPage;
