import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Table,
  Avatar,
  List,
  Row,
  Col,
  Segmented,
  Button,
  Modal,
  Select,
  Skeleton
} from "antd";
import {
  PhoneOutlined,
  MailOutlined,
  UserOutlined,
  HomeOutlined,
  CheckCircleOutlined,
  FieldTimeOutlined
} from "@ant-design/icons";
import ReactMapboxGl from "react-mapbox-gl";
import mapboxgl from "mapbox-gl";
import ReactFullScreen from "react-easyfullscreen";
import request from "helper/request";
import { formatToUSD } from "helper/util";
import { canEditPermission } from "configs/constants";
import { allowPermission } from "helper/util";
import { useSelector } from "react-redux";
import { selectRole } from "modules/auth/store/authSlice";
import { useDispatch } from "react-redux";
import { assignDriver, reassignDriver } from "modules/client/routes/store/thunks";
import { setSuccessMessage } from "app/global/slice";
import StatisticCard from "components/StatisticCard";
import PickupDropoffContainer from "modules/shared/PickupDropoff/PickupDropoffContainer";
import MapBox from "modules/shared/PickupDropoff/mapbox/Mapbox";
import { ONE_HOUR_PX } from "modules/shared/PickupDropoff/constants";
import { decodePolyline } from "modules/shared/PickupDropoff/decodePolyline";
import { Link, useHistory } from "react-router-dom";
import { mapLocations } from "modules/client/routes/store/slice";
import routeIcon from "../../../../components/icons/route.svg";
import trophyIcon from "../../../../components/icons/trophy.svg";
import waitIcon from "../../../../components/icons/wait.svg";
import { InfoIcon } from "modules/shared/PickupDropoff/svgs";
import _ from "lodash";
import moment from "moment";
import { setMapboxRTLTextPlugin } from "helper/util";

mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
setMapboxRTLTextPlugin(mapboxgl);
const Map = ReactMapboxGl({
  minZoom: 8,
  accessToken: window.REACT_APP_MAPBOX_KEY
});

const MODAL_WIDTH = 1024;
const scrollHeight = 500;

function DelayedRouteList({
  onAssignDriverClick,
  onReassignDriverClick,
  onUnassignDriverClick
}) {
  const dispatch = useDispatch();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [needsAttentionData, setNeedsAttentionData] = useState({});
  const [statisticsData, setStatisticsData] = useState({});
  const [showAssignDriver, setShowAssignDriver] = useState(false);
  const [currentRoute, setCurrentRoute] = useState({});
  const [assignedDriverId, setAssignedDriverId] = useState("");
  const [oneHourPx] = useState(ONE_HOUR_PX);
  const isLoading = useSelector((state) => state.global.isLoading);
  const [routeTableSource, setRouteTableSource] = useState([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [openCompletedModal, setOpenCompletedModal] = useState(false);
  const [openFailedModal, setOpenFailedModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("all");

  useEffect(() => {
    if (!window.ROUTES_DELAYED) {
      return history.push("/admin/");
    }
  }, []);

  const handleSubmitAssignDriver = async () => {
    if (!assignedDriverId) return;
    try {
      const { error } = await dispatch(
        currentRoute?.driverId
          ? reassignDriver({ routeId: currentRoute?.id, driverId: assignedDriverId })
          : assignDriver({ routeId: currentRoute?.id, driverId: assignedDriverId })
      );
      if (!error) {
        dispatch(
          setSuccessMessage(
            `${currentRoute?.driverId ? "Reassigned" : "Assigned"} successfully!`
          )
        );
        setShowAssignDriver(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const columns = useMemo(() => [
    {
      title: "Route ID",
      dataIndex: "id",
      render: (value) => (
        <>
          <Link to={`/admin/routes/${value}`}>
            {value?.substring(0, 8).toUpperCase()}
          </Link>
        </>
      )
    },
    {
      title: "Client",
      dataIndex: "client",
      render: (value) => <span>{value?.clientName}</span>
    },
    {
      title: "Service",
      dataIndex: "service",
      render: (value) => <span>{value?.name}</span>
    },
    {
      title: "Pickup Date",
      dataIndex: "startDate",
      render: (value) => <span>{moment(value).format("YYYY-MM-DD")}</span>
    },
    {
      title: "Driver",
      dataIndex: "driver",
      render: (value) => (
        <>
          {value?.firstName} {value?.lastName}
        </>
      )
    },
    {
      title: "Driver Estimate",
      dataIndex: "driverEstimatePrice",
      render: (value) => formatToUSD(value)
    }
  ]);

  const failedColumns = useMemo(() => [
    {
      title: "Job ID",
      dataIndex: "jobId",
      render: (value, record) => (
        <>
          <Link to={`/admin/routes/${record?.routeId}/${value}`}>
            {value.substring(0, 8).toUpperCase()}
          </Link>
        </>
      ),
      width: 120
    },
    {
      title: "Route ID",
      dataIndex: "routeId",
      render: (value) => (
        <>
          <Link to={`/admin/routes/${value}`}>{value.substring(0, 8).toUpperCase()}</Link>
        </>
      ),
      width: 120
    },
    {
      title: "Driver ID",
      dataIndex: "driverId",
      render: (value) => (
        <>
          <Link to={`/admin/users/drivers/${value}`}>
            {value?.substring(0, 8).toUpperCase()}
          </Link>
        </>
      ),
      width: 120
    },
    {
      title: "Driver Name",
      render: (record) => (
        <>
          {record?.driverFirstName} {record?.driverLastName}
        </>
      )
    },
    {
      title: "Failed Reason",
      dataIndex: "groupStatusDate",
      render: (value) => <>{value?.failedAt?.note || "N/A"}</>
    },
    {
      title: "Pickup Address",
      dataIndex: "pickupAddress",
      render: (value) => <>{value}</>
    },
    {
      title: "Dropoff Address",
      dataIndex: "dropoffAddress",
      render: (value) => <>{value}</>
    }
  ]);
  const completedColumns = useMemo(() => [
    {
      title: "Route ID",
      dataIndex: "id",
      render: (value) => (
        <>
          <Link to={`/admin/routes/${value}`}>{value.substring(0, 8).toUpperCase()}</Link>
        </>
      )
    },
    {
      title: "Client",
      dataIndex: "client",
      render: (value) => <>{value?.name}</>
    },
    {
      title: "Service",
      dataIndex: "service",
      render: (value) => <>{value?.name}</>
    },
    {
      title: "Pickup Date",
      dataIndex: "pickupDate",
      render: (value) => <>{moment(value).format("YYYY-MM-DD")}</>
    },
    {
      title: "Driver",
      dataIndex: "driver",
      render: (value) => (
        <>
          {value?.firstName} {value?.lastName}
        </>
      )
    },
    {
      title: "Driver Estimate",
      dataIndex: "driverEstimatePrice",
      render: (value) => <>{formatToUSD(value)}</>
    }
  ]);

  const needsAttentionInterval = useRef(null);

  const needsAttentionPolling = useCallback((minutes) => {
    if (isNaN(minutes) || !minutes) return;
    clearInterval(needsAttentionInterval.current);
    needsAttentionInterval.current = setInterval(() => {
      callDelayedRoutesData();
    }, minutes * 60 * 1000);
  }, []);

  const stopNeedsAttentionPolling = useCallback(() => {
    clearInterval(needsAttentionInterval.current);
    needsAttentionInterval.current = null;
  }, []);

  useEffect(() => {
    needsAttentionPolling(5);
    return () => {
      stopNeedsAttentionPolling();
    };
  }, []);

  useEffect(() => {
    callDelayedRoutesData();
  }, [setLoading]);

  useEffect(() => {
    if (needsAttentionData[selectedTab]) {
      let routeSourceWithKeys = needsAttentionData[selectedTab].map((data, index) => ({
        key: index + 1,
        ...data
      }));
      setRouteTableSource(routeSourceWithKeys);
    }
  }, [needsAttentionData]);

  const callDelayedRoutesData = async () => {
    setLoading(true);
    await getNeedsAttentioRoutes();
    await getStatistics();
    setLoading(false);
  };

  const getStatistics = useCallback(async () => {
    try {
      const statisticsRes = await request.get("/admin/routes/routes/statistics");
      if (!statisticsRes?.data) return;

      setStatisticsData(statisticsRes?.data);
    } catch (error) {
      console.log(error);
    }
  }, [setStatisticsData]);

  const getNeedsAttentioRoutes = useCallback(async () => {
    try {
      const needsAttentionRes = await request.get("/admin/routes/routes/needs-attention");
      if (!needsAttentionRes?.data) return;
      const routesConverted = convertToTableSource(needsAttentionRes?.data);

      setNeedsAttentionData(needsAttentionRes?.data);
      setRouteTableSource(routesConverted);
    } catch (error) {
      console.log(error);
    }
  }, [setNeedsAttentionData, setRouteTableSource]);

  const convertToTableSource = (routes) => {
    let allRoutes = [];
    for (const statusKey in routes) {
      allRoutes = [...allRoutes, ...routes[statusKey]];
    }

    allRoutes = _.uniqBy(allRoutes, "id");
    const transformedRoutes = allRoutes.map((route, index) => ({
      key: index + 1,
      ...route,
      coords: route?.polylines ? decodePolyline(route.polylines).coords : [],
      display: true,
      lat: route?.startAddressLat,
      lng: route?.startAddressLng
    }));

    return transformedRoutes;
  };
  return (
    <div>
      <h2 className="title">Today Stats</h2>
      <div style={{ display: "flex" }}>
        {window.ROUTE_STATS_DELAYED && (
          <>
            {loading ? (
              <Row gutter={20} style={{ width: "100%" }}>
                <Col md={8}>
                  <Skeleton active />
                </Col>
                <Col md={8}>
                  <Skeleton active />
                </Col>
                <Col md={8}>
                  <Skeleton active />
                </Col>
              </Row>
            ) : (
              <>
                <StatisticCard
                  title="Total Routes Unassigned"
                  className="m-1 stats-card"
                  icon={
                    <span
                      style={{
                        background: `url(${waitIcon}) center no-repeat`,
                        backgroundSize: "contain",
                        width: "14em",
                        height: "14em",
                        display: "block",
                        opacity: 0.1,
                        marginTop: "45%"
                      }}
                    ></span>
                  }
                  data={`${statisticsData?.totalUnassigned || 0}/${
                    statisticsData?.totalRoutes
                  } Unassigned`}
                />
                <StatisticCard
                  title="Total Routes Completed"
                  className="m-1 stats-card"
                  handleMenu={() => setOpenCompletedModal(true)}
                  showMenu={true}
                  icon={
                    <span
                      style={{
                        background: `url(${trophyIcon}) center no-repeat`,
                        backgroundSize: "contain",
                        width: "14em",
                        height: "14em",
                        display: "block",
                        opacity: 0.1,
                        marginTop: "45%"
                      }}
                    ></span>
                  }
                  data={`${statisticsData?.totalCompleted || 0}/${
                    statisticsData?.totalRoutes
                  } Completed`}
                />
                <StatisticCard
                  title="Failed Stops"
                  className="m-1 stats-card"
                  handleMenu={() => setOpenFailedModal(true)}
                  showMenu={true}
                  icon={
                    <span
                      style={{
                        background: `url(${routeIcon}) center no-repeat`,
                        backgroundSize: "contain",
                        width: "18em",
                        height: "18em",
                        display: "block",
                        opacity: 0.1,
                        marginTop: "15%"
                      }}
                    ></span>
                  }
                  data={`${statisticsData?.totalFailedJobs || 0} Failed`}
                />
              </>
            )}
          </>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <h2 className="title">Needs Attention</h2>
        <a
          href="https://flexio-help.helpcenter.io/content/dispatch-center?ref=app"
          target="_blank"
          rel="noreferrer"
        >
          <InfoIcon
            style={{
              width: "20px",
              height: "20px",
              position: "relative",
              top: "5px",
              left: "5px"
            }}
          />
        </a>
      </div>
      <Segmented
        className="mb-2"
        options={[
          {
            value: "all",
            label: `All (${convertToTableSource(needsAttentionData).length})`,
            icon: <FieldTimeOutlined />
          },
          {
            value: "unassigned",
            label: `Unassigned and Starting Soon (
            ${needsAttentionData?.unassigned?.length || 0})`
          },
          {
            value: "assignedNotStarted",
            label: `Assigned and Not Started (${
              needsAttentionData?.assignedNotStarted?.length || 0
            })`
          },
          {
            /*
            Assigned and started = route has a driver and they started working on it (route is in progress
            instead of only accepted), but it has been 1+ hours after the pickup time window ended
            and the pickup hasn't been completed
            */
            value: "assignedStarted",
            label: `Missed Pickup Window (${
              needsAttentionData?.assignedStarted?.length || 0
            })`
          },
          {
            /*
            Delayed Stops = route is in progress, but it's been 1+ hours after the pickup/dropoff
            time window ended and the stop hasn't been completed (for any of the stops of the route)
            */
            value: "delayedLocations",
            label: `Delayed Dropoffs (${
              needsAttentionData?.delayedLocations?.length || 0
            })`
          }
        ]}
        onChange={(value) => {
          setSelectedTab(value);
          if (value === "all") {
            return setRouteTableSource(convertToTableSource(needsAttentionData));
          }
          let routeSourceWithKeys = needsAttentionData[value].map((data, index) => ({
            key: index + 1,
            ...data
          }));

          setRouteTableSource(routeSourceWithKeys);
        }}
      />
      <Table
        dataSource={routeTableSource}
        columns={columns}
        pagination={false}
        bordered={false}
        loading={loading}
        expandable={{
          expandedRowRender: (record) => (
            <Row>
              <Col md={12}>
                <div>
                  {record?.driver?.id && (
                    <div>
                      <h3>Driver Information</h3>
                      <List
                        itemLayout="horizontal"
                        dataSource={[record?.driver || {}]}
                        renderItem={(item, index) => (
                          <List.Item key={index}>
                            <List.Item.Meta
                              avatar={
                                <Avatar
                                  style={{ width: 60, height: 60 }}
                                  src={item?.profilePhoto}
                                  icon={
                                    <UserOutlined
                                      style={{ fontSize: 30, marginTop: 10 }}
                                    />
                                  }
                                />
                              }
                              title={
                                <>
                                  {item?.firstName} {item?.lastName}
                                </>
                              }
                              description={
                                <Link to={`/admin/users/drivers/${record?.driver?.id}`}>
                                  {record?.driver?.id?.substring(0, 8).toUpperCase()}
                                </Link>
                              }
                            />
                            <List.Item.Meta
                              title={"Information"}
                              description={
                                <>
                                  <div>
                                    <PhoneOutlined className="mr-2" /> {item?.phone}
                                  </div>
                                  <div>
                                    <MailOutlined className="mr-2" /> {item?.email}
                                  </div>
                                  <div>
                                    <HomeOutlined className="mr-2" />{" "}
                                    {item?.postalAddress}
                                  </div>
                                </>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </div>
                  )}

                  <h3>Client Contact Information</h3>

                  <Row>
                    <Col md={8}>
                      <h4>Business Name</h4>
                      <p>{record?.client?.clientName}</p>
                    </Col>
                    <Col md={8}>
                      <h4>Phone Number</h4>
                      <p>
                        <PhoneOutlined className="mr-2" />
                        {record?.client?.supportPhone}
                      </p>
                    </Col>
                    <Col md={8}>
                      <h4>Email</h4>
                      <p>
                        <MailOutlined className="mr-2" />
                        {record?.client?.supportEmail}
                      </p>
                    </Col>
                    <Col md={8}>
                      <h4>Address</h4>
                      <p>
                        <HomeOutlined className="mr-2" />
                        {record?.client?.address}
                      </p>
                    </Col>
                    <Col md={8}>
                      <h4>Created By</h4>
                      <p>
                        <UserOutlined className="mr-2" />
                        {record?.user?.firstName ?? "N/A"}{" "}
                        {record?.user?.lastName ?? "N/A"}
                      </p>
                      <p>
                        <MailOutlined className="mr-2" />
                        {record?.user?.email ?? "N/A"}
                      </p>
                      <p>
                        <PhoneOutlined className="mr-2" />
                        {record?.user?.phone ?? "N/A"}
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={12}>
                <ReactFullScreen>
                  {({ ref, onRequest, onExit }) => (
                    <div ref={ref}>
                      <PickupDropoffContainer
                        stops={mapLocations([record])}
                        routes={[record]}
                        oneHourPx={oneHourPx}
                      >
                        {({
                          handleClickMarker,
                          goToCoupleStop,
                          center,
                          containerRoutes,
                          mapStops,
                          bounds,
                          driverDetail,
                          setDriverDetail,
                          showDriverDetail,
                          setShowDriverDetail
                        }) => {
                          return (
                            <MapBox
                              handleClickMarker={handleClickMarker}
                              stops={mapStops}
                              center={center}
                              routes={containerRoutes}
                              order={containerRoutes}
                              goToCoupleStop={goToCoupleStop}
                              height={600}
                              bounds={bounds}
                              driverDetail={driverDetail}
                              setDriverDetail={setDriverDetail}
                              showDriverDetail={showDriverDetail}
                              setShowDriverDetail={setShowDriverDetail}
                              fitBoundsOptions={{ padding: 40 }}
                            />
                          );
                        }}
                      </PickupDropoffContainer>
                    </div>
                  )}
                </ReactFullScreen>
              </Col>
            </Row>
          ),
          // rowExpandable: (record) => !record?.driver,
          defaultExpandAllRows: true
        }}
      />
      {showAssignDriver && (
        <Modal
          closable={false}
          visible={true}
          title={`${currentRoute?.driverId ? "Reassign" : "Assign"} Driver`}
          width={600}
          footer={
            <div className="buttons-modal">
              <Button size="large" onClick={() => setShowAssignDriver(false)}>
                Cancel
              </Button>
              <Button
                disabled={
                  !assignedDriverId || assignedDriverId === currentRoute?.driverId
                }
                loading={isLoading}
                size="large"
                type="primary"
                onClick={handleSubmitAssignDriver}
              >
                Assign
                <CheckCircleOutlined />
              </Button>
            </div>
          }
        >
          <Select
            size="large"
            placeholder="Select driver"
            onChange={(driverId) => setAssignedDriverId(driverId)}
            style={{ width: "100%" }}
            showSearch
            optionFilterProp="children"
            value={assignedDriverId}
            filterOption={(input, option) =>
              option?.children?.toLowerCase().includes(input.toLowerCase())
            }
          ></Select>
        </Modal>
      )}
      {/* COMPLETED MODAL */}
      <Modal
        onCancel={() => setOpenCompletedModal(false)}
        open={openCompletedModal}
        title={"Completed Routes"}
        footer={false}
        width={MODAL_WIDTH}
      >
        <Table
          pagination={false}
          columns={completedColumns}
          dataSource={statisticsData?.completedRoutes}
          scroll={{ y: scrollHeight }}
        />
      </Modal>
      {/* FAILED MODAL */}
      <Modal
        onCancel={() => setOpenFailedModal(false)}
        open={openFailedModal}
        title={"Failed Stops"}
        footer={false}
        width={1200}
      >
        <Table
          pagination={false}
          columns={failedColumns}
          dataSource={statisticsData?.failedJobs}
          scroll={{ y: scrollHeight }}
        />
      </Modal>
    </div>
  );
}

export default DelayedRouteList;
