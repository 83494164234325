import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { selectAuthentication, selectGroup } from "../modules/auth/store/authSlice";
import DriverMainLayout from "layouts/privateDriver/MainLayout";
import { UserGroup } from "configs/constants";
import { DriverProfileProvider } from "layouts/privateDriver/DriverProfileContext";
import Cookies from "universal-cookie";
import { TOKEN_NAME } from "configs/constants";
import { removeAmazonConnectChatElement } from "helper/util";

const PrivateDriverRoute = (props) => {
  const { component: Component, isFullScreen = false, ...rest } = props;
  const authentication = useSelector(selectAuthentication);
  const cookies = new Cookies();

  useEffect(() => {
    loadDriverAmazonConnectChat();
  }, [cookies]);

  const loadDriverAmazonConnectChat = () => {
    const token = cookies.get(TOKEN_NAME);
    if (!token) {
      removeAmazonConnectChatElement();
      return;
    }
    const script = document.createElement("script");
    script.innerHTML = `
      (function(w, d, x, id){
        s=d.createElement('script');
        s.src='https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js';
        s.async=1;
        s.id=id;
        d.getElementsByTagName('head')[0].appendChild(s);
        w[x] =  w[x] || function() { (w[x].ac = w[x].ac || []).push(arguments) };
      })(window, document, 'amazon_connect', 'cfe097be-e9ce-46ae-9497-23c6d8b1c9f2');
      amazon_connect('styles', { iconType: 'CHAT', openChat: { color: '#ffffff', backgroundColor: '#004ebb' }, closeChat: { color: '#ffffff', backgroundColor: '#004ebb'} });
      amazon_connect('snippetId', 'QVFJREFIaWFZYXRVSlpIekdkUUg5YXhZenVQMktKRXNIWTVFQWpBYVErTEdzRnpvZHdFZkZPc3RSOG1KOVJyMURyQTgvaHZ5QUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNZHdHRGhZUWZuRHRRUmEyZEFnRVFnQ3U2d2swTWxjSWJrQWR5QlNqU2FJOTNrdzJIM0c2OVBnendoSGZ1NEJNb1BFd3J0TWxJbGl5WlBvWGs6OnNmZEhUbldCbXFlU3paSC8xd2JGNUo2ZEFwWWVPYkhKY2pXZkJDeWlib0pMYzJqY2JEK3ZJbGhYbE5rOThFN2FWNFZINUdUUVV3MEZ0UTRBeVpqeUFOUWNYVWpZYVNVOWZNaFhENmpoN3pUOFd5Tmd0QkpQdDFkR1VvR0owU05PMTJPdWtlTVVaWVhsUWNBU2ZxYXluQmtwOVovK3lITT0=');
      amazon_connect('supportedMessagingContentTypes', [ 'text/plain', 'text/markdown', 'application/vnd.amazonaws.connect.message.interactive', 'application/vnd.amazonaws.connect.message.interactive.response' ]);
  `;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  if (!authentication) {
    return (
      <Redirect
        to={{
          pathname: `/driver/login`
        }}
      />
    );
  }
  return (
    <Route
      {...rest}
      render={(propsCom) => {
        return (
          <DriverProfileProvider>
            <DriverMainLayout isFullScreen={isFullScreen}>
              <Component {...propsCom} />
            </DriverMainLayout>
          </DriverProfileProvider>
        );
      }}
    />
  );
};
export default PrivateDriverRoute;
