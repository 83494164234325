import React, { useEffect, useState } from "react";
import PromoCodeEditForm from "../form/PromoCodeEditForm";
import { useParams } from "react-router-dom";
import request from "helper/request";
import { Form } from "antd";
import moment from "moment";

const PromoCodeEdit = () => {
  const params = useParams();
  const [form] = Form.useForm();

  useEffect(() => {
    getPromoCodeById();
  }, []);

  const getPromoCodeById = async () => {
    try {
      const result = await request.get(`/admin/promo-code/${params?.id}`);

      form.setFieldsValue({
        ...result,
        expiryDate: moment(result?.expiryDate)
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updatePromoCode = async (data) => {
    try {
      await request.patch(`/admin/promo-code/${params?.id}`, data);
    } catch (error) {
      console.log(error);
    }
  };

  return <PromoCodeEditForm form={form} updatePromoCode={updatePromoCode} />;
};

export default PromoCodeEdit;
