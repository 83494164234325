import { notification, Spin } from "antd";
import request from "helper/request";
import React, { useEffect, useState } from "react";
import "./DriverRegisterPage.scss";
import {
  InputPasswordHook,
  InputHook,
  PhoneInputHook,
  CheckBoxHook
} from "components/hook-forms";
import Cards from "modules/client/drivers/components/Cards";
import { Button } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { registerForm } from "modules/client/drivers/forms/drivers/schema";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

const DriverRegister = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadDriverRegisterAmazonConnectChat();
  }, []);

  const loadDriverRegisterAmazonConnectChat = () => {
    const script = document.createElement("script");
    script.innerHTML = `
      (function(w, d, x, id){
        s=d.createElement('script');
        s.src='https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js';
        s.async=1;
        s.id=id;
        d.getElementsByTagName('head')[0].appendChild(s);
        w[x] =  w[x] || function() { (w[x].ac = w[x].ac || []).push(arguments) };
      })(window, document, 'amazon_connect', 'e0a4c73d-ee4a-4982-8766-05c8069698d0');
      amazon_connect('styles', { iconType: 'CHAT', openChat: { color: '#ffffff', backgroundColor: '#004ebb' }, closeChat: { color: '#ffffff', backgroundColor: '#004ebb'} });
      amazon_connect('snippetId', 'QVFJREFIaWFZYXRVSlpIekdkUUg5YXhZenVQMktKRXNIWTVFQWpBYVErTEdzRnpvZHdIVmVGd3l3dFdvNU1LNXdua1ZwelJuQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNRUJYdDd5T1ZlRXFySlpXM0FnRVFnQ3VrNlVkZUx1MEt1RVU3b3luRnh4ZFBYSFJvR0xUZ3I3MHF1TFk5bEVTT1dSVDZCaUlibmx6UnZNUzE6OnRiWjNkWXdRcFRuTHV6QXNvMXVWaE00VUczaDEwVlNQd1pjd2V4Z3RYRGp6ekpRZGZxcWc2K0NzRVB6TzFJWnZRNTFCckovbnVuZ1l0a0NEUmFPdTk5clhoYUpEK0Q4aXA0RTlDZm1BWVU0ZGNwZVVvKzhwb0RZOG9IOERNUVpFMTBMWXRaVHE2cG9vaHhtWHVtbzZ4dkFRUCtuYWFsWT0=');
      amazon_connect('supportedMessagingContentTypes', [ 'text/plain', 'text/markdown', 'application/vnd.amazonaws.connect.message.interactive', 'application/vnd.amazonaws.connect.message.interactive.response' ]);
  `;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  const handleSubmitForm = async (values) => {
    setIsLoading(true);
    try {
      await request.post("/drivers/register", { ...values });
      window.location.href = "/drivers/register/success";
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: JSON.parse(e?.message)?.message
      });
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(registerForm)
  });

  return (
    <Spin spinning={isLoading}>
      <div className="driver-register-page">
        <div className="background-section" />
        <div className="main-content-page">
          <div className="wrap-logo">
            <img src={`/logos/user/${window.REACT_APP_LOGO_FILE_NAME}`} />
          </div>
          <div className="wrap-form-content" style={{ marginTop: "20px" }}>
            <div className="wrap-steps">
              <div className="title" style={{ marginBottom: 0 }}>
                Drivers Registration
              </div>
            </div>
            <div className="wrap-form">
              <form
                onSubmit={handleSubmit(handleSubmitForm)}
                style={{ border: "solid 2px white" }}
              >
                <Cards rightTitle="All fields are required">
                  <div className="wrap-step-1-form">
                    <InputHook
                      control={control}
                      label="First Name"
                      placeholder="First Name"
                      name="firstName"
                      showOptionsName={false}
                      errors={errors}
                    />
                    <InputHook
                      control={control}
                      label="Last Name"
                      placeholder="Last Name"
                      name="lastName"
                      showOptionsName={false}
                      errors={errors}
                    />
                    <InputHook
                      control={control}
                      label="Email"
                      placeholder="Email"
                      name="email"
                      showOptionsName={false}
                      errors={errors}
                    />
                    <InputPasswordHook
                      control={control}
                      label="Password"
                      placeholder="Password"
                      name="password"
                      showOptionsName={false}
                      errors={errors}
                    />
                    <InputPasswordHook
                      control={control}
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      showOptionsName={false}
                      errors={errors}
                    />
                    <PhoneInputHook
                      control={control}
                      label="Phone Number"
                      placeholder="Phone Number"
                      name="phone"
                      showOptionsName={false}
                      errors={errors}
                      required={true}
                    />
                    <CheckBoxHook
                      showOptionsName={false}
                      control={control}
                      name="agree"
                      errors={errors}
                      classNameCheckbox="secondary"
                      showLabel={false}
                      className="agree-checkbox"
                    >
                      <div>
                        <span>I agree with Flexio &nbsp;</span>
                        <Link
                          to={{
                            pathname: isMobile
                              ? "https://www.goflexio.com/driverterms_eng"
                              : "https://www.goflexio.com/clientterms"
                          }}
                          target="_blank"
                        >
                          <span className="link">Terms & Conditions</span>
                        </Link>
                      </div>
                    </CheckBoxHook>
                    <Button type="primary" htmlType="submit" style={{ marginTop: 8 }}>
                      Complete Registration
                    </Button>
                  </div>
                  <div
                    className=""
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "-20px",
                      padding: "20px"
                    }}
                  >
                    <p className="text-center text-gray-300">
                      {"Already have an account?"} <Link to="/driver/login">LogIn</Link>
                    </p>
                  </div>
                </Cards>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};
export default DriverRegister;
